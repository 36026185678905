import React, { useState } from 'react';
import axios from 'axios'; // Make sure axios is installed and imported
import styles from './SignUpPage.module.css';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext'; // Import the context

// Add a delay utility function
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const ResetPage = () => {
  const navigate = useNavigate();

  // Move useAppContext inside the component
  const { showModal, hideModal } = useAppContext();

  // State for form inputs
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(styles.signUpotp); // Track OTP sending status
  const [resendDisabled, setResendDisabled] = useState(false); // Control Resend OTP button
  const [timeLeft, setTimeLeft] = useState(60); // Timer for Resend OTP

  // Function to validate form
  const validateForm = async () => {
    await delay(2000); // Add a 2-second delay before returning false

    if (!email || !password || !otp) {
      showModal({
        circularProgress: false,
        topic: 'Fill Details',
        description: 'Please fill out all fields.',
        primaryBtnColor: 'red',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal(); // Hide the modal on click
        }
      });
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email validation pattern
    if (!emailPattern.test(email)) {
      showModal({
        circularProgress: false,
        topic: 'Check Email',
        description: 'Please enter a valid email address.',
        primaryBtnColor: 'red',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal(); // Hide the modal on click
        }
      });
      return false;
    }
    return true;
  };

  // Function to request OTP
  const handleGetOtp = async () => {
    showModal({
      circularProgress: true,
      topic: 'Checking login Details...',
      description: 'Please wait...',
    });

    if (!resendDisabled) {
      setLoading(true);
      try {
        const response = await axios.post('https://prizeclasses.in/sql/otp.php', {
          action: 'otp',
          email,
        });
  
        const { status, message } = response.data;
        showModal({
          circularProgress: false,
          topic: status === 'success' ? 'OTP Sent' : 'OTP Error',
          description: message,
          primaryBtnColor: status === 'success' ? 'green' : 'red',
          primaryBtnText: 'OK',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal(); // Hide modal on click
          }
        });
  
        if (status === 'success') {
          startResendOtpTimer();
        }
      } catch (error) {
        showModal({
          circularProgress: false,
          topic: 'Error',
          description: 'Unable to send OTP.',
          primaryBtnColor: 'red',
          primaryBtnText: 'OK',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal(); // Hide the modal on click
          }
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // Timer for disabling the "Resend OTP" button
  const startResendOtpTimer = () => {
    setResendDisabled(true);
    setOtpSent(styles.signUpotpgray);
    const countdown = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdown);
          setResendDisabled(false);
          setOtpSent(styles.signUpotp);
          setTimeLeft(60); // Reset timer
          return 60;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  // Handle password reset
  const handleReset = async () => {
    showModal({
      circularProgress: true,
      topic: 'Checking reset Details...',
      description: 'Please wait...',
    });
    if (!await validateForm()) { // Await form validation
      return; // Stop execution if validation fails
    }

    try {
      setLoading(true);
      const response = await axios.post('https://prizeclasses.in/sql/reset.php', {
        action: 'reset',
        email,
        otp,
        password
      });
    
      const { status, message } = response.data;
        showModal({
          circularProgress: false,
          topic: status === 'success' ? 'Success' : 'Error',
          description: message,
          primaryBtnColor: status === 'success' ? 'green' : 'red',
          primaryBtnText: 'OK',
          hidePrimary: false,
          onPrimaryClick: status === 'success' 
          ? () => {
            hideModal(); // Hide the modal on click
              navigate('/login');
            } 
          : () => {
              hideModal(); // Hide the modal on click
            }
      
        });

    } catch (error) {
      showModal({
        circularProgress: false,
        topic: 'Error',
        description: 'There was an error resetting your password.',
        primaryBtnColor: 'red',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal(); // Hide the modal on click
        }
      });
    } finally {
      setLoading(false);
    }
  }    

  return (
    <div className={styles.signUpmain}>
      <div className={styles.signUpcontainer}>
        <div className={styles.signUpheader}>
          <WestOutlinedIcon className={styles.signUpicon} onClick={() => navigate('/login')} />
          <div className={styles.signUptext}>Reset</div>
        </div>
        <div className={styles.signUpinputs}>
          <div className={styles.signUpinput}>
            <EmailOutlinedIcon className={styles.signUpicon} />
            <input
              type="email"
              placeholder="Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.signUpinput}>
            <SmsOutlinedIcon className={styles.signUpicon} />
            <input
              type="text"
              placeholder="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <div
              className={otpSent}
              onClick={handleGetOtp}
              disabled={resendDisabled} // Disable Resend OTP button
            >
              {resendDisabled ? `Retry in ${timeLeft}s` : 'Get OTP'}
            </div>
          </div>
          {resendDisabled && (
            <div style={{ color: 'red' }} className={styles.signUpinfo}>
              OTP sent to email inbox/junkbox
            </div>
          )}

          <div className={styles.signUpinput}>
            <LockOutlinedIcon className={styles.signUpicon} />
            <input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.signUpsubmitcontainer}>
          <div className={styles.signUpreset} onClick={handleReset} disabled={loading}>
            Reset
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPage;
