import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';

function SubsPage() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [premiumData, setPremiumData] = useState(null);
  const { showModal, hideModal } = useAppContext();

  useEffect(() => {
    const userdata = localStorage.getItem('userdata');
    if (userdata) {
      const user = JSON.parse(userdata);
      setProfileData(user);
      fetch(`https://prizeclasses.in/sql/plan.php?userId=${user.user}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.status !== 'error') {
            setPremiumData(data.status);
            localStorage.setItem('plan', data.status);
          }
        });
    } else {
      showModal({
        circularProgress: false,
        topic: 'UserID Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');

        }
      });    }
  }, [hideModal,showModal,navigate]);

  const handleBackClick = () => {
    navigate('/');
  };

  const handleCall = async() => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Show loading modal
    showModal({
      circularProgress: true,
      topic: 'Upgrading Plan...',
      description: 'Please wait...',
    });
    await delay(2000);
    if (profileData?.user) {
    fetch(`https://prizeclasses.in/sql/plan.php?userId=${profileData?.user}&serviceid=123`)
    .then((response) => response.json())
    .then((data) => {
      if (data.status !== 'error') {
        setPremiumData(data.status);
        localStorage.setItem('plan', data.status);
        showModal({
          circularProgress: false,
          topic: 'Paid Plan',
          description:'Your plan is upgraded to Premium succesfully ',
          primaryBtnText: 'OK',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
            window.location.reload(true);  
          }
        });

      }else{
        showModal({
          circularProgress: false,
          topic: 'Upgrading Plan Fail',
          description:data.message,
          primaryBtnText: 'OK',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
          }
        });
      }
    });
} else {
  console.log('error');
}
  };

  // Define missing styles
  
  const PlanCard = ({ title, price, features, actionText }) => {
    const isStandard = premiumData === 'Standard';
    const isPremium = premiumData === 'Premium';

  const cardTitleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
    margin: '10px 0',
  };

  const cardPriceStyle = {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '10px',
  };

  const cardFeaturesStyle = {
    listStyle: 'none',
    padding: 0,
    margin: '20px 0',
  };

  const featureItemStyle = {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0',
  };

  const checkmarkStyle = {
    color: '#28a745',
    fontWeight: 'bold',
  };

  const crossmarkStyle = {
    color: '#ff4d4f',
    fontWeight: 'bold',
  };

  const cardActionStyle = {
    backgroundColor: '#ff6600',
    color: 'white',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    display:isStandard && title === 'Free Plan' ? 'None' : isPremium && title === 'Premium Plan' ? 'None' : isStandard && title === 'Premium Plan' ? 'Visible' : 'None',
  };


    const cardContainerStyle = {
      backgroundColor: '#fff',
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
      padding: '20px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      maxWidth: '500px',
      width: '80%',
      margin: '20px',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
    };

    const cardHeaderStyle = {
      fontSize: '18px',
      fontWeight: 'bold',
      padding: '10px 0',
      color: '#fff',
      backgroundColor: isStandard && title === 'Free Plan' ? 'green' : isPremium && title === 'Premium Plan' ? 'green' : isStandard && title === 'Premium Plan' ? '#ff6600' : 'grey',
      borderRadius: '10px 10px 0 0',
    };

    return (
      <div style={cardContainerStyle}>
        <div style={cardHeaderStyle}>
        {isStandard && title === 'Free Plan' ? 'Current Plan' : isPremium && title === 'Premium Plan' ? 'Current Plan' : isStandard && title === 'Premium Plan' ? 'Upgrade Plan' : 'Last Plan'}</div>

        <div style={cardTitleStyle}>{title}</div>

        <div style={cardPriceStyle}>₹{price}/mo</div>

        <ul style={cardFeaturesStyle}>
          {features.map((feature, index) => (
            <li key={index} style={featureItemStyle}>
              {feature.name}
              <span style={feature.available ? checkmarkStyle : crossmarkStyle}>
                {feature.available ? '✔' : '✘'}
              </span>
            </li>
          ))}
        </ul>

        <button style={cardActionStyle} onClick={handleCall}>
          {actionText}
        </button>
      </div>
    );
  };

  const freePlanFeatures = [
    { name: 'Free SSC Batch Access', available: true },
    { name: 'Free UPSC Batch Access', available: true },
    { name: 'Free POLICE Batch Access', available: true },
    { name: 'Free Access All Chapters', available: true },
    { name: 'Access To Contest', available: true },
    { name: 'Limited Study Material', available: true },
    { name: 'Access Chapter Quiz', available: false },
    { name: 'Mock Tests And Quizzes', available: false },
    { name: 'PDF Study Material', available: false },
    { name: 'PDF Last year Question Paper', available: false },
    { name: 'Mock Test And Quizz Result Download', available: false },
  ];

  const premiumPlanFeatures = [
    { name: 'Free SSC Batch Access', available: true },
    { name: 'Free UPSC Batch Access', available: true },
    { name: 'Free POLICE Batch Access', available: true },
    { name: 'Free Access All Chapters', available: true },
    { name: 'Access To Contest', available: true },
    { name: 'Unlimited Study Material', available: true },
    { name: 'Access Chapter Quiz', available: true },
    { name: 'Mock Tests And Quizzes', available: true },
    { name: 'PDF Study Material', available: true },
    { name: 'PDF Last year Question Paper', available: true },
    { name: 'Mock Test And Quizz Result Download', available: true },
  ];

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px',
    marginTop: '20px',
  };

  return (
    <div>
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Subscriptions
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={containerStyle}>
        <PlanCard
          title="Free Plan"
          price="0.00"
          features={freePlanFeatures}
          actionText="Current Plan"
        />

        <PlanCard
          title="Premium Plan"
          price="199.00"
          features={premiumPlanFeatures}
          actionText="Upgrade Now"
        />
      </div>
    </div>
  );
}

export default SubsPage;
