// ContainerPage.js
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BatchContext } from '../BatchContext';  // Import BatchContext
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const ContainerPage = () => {
  const { chapterId } = useParams();  // Get the chapterId from the URL
  const { chapters, batch } = useContext(BatchContext);  // Access the chapters from context
  const [chapterData, setChapterData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (chapters && chapters.length > 0) {
      
      setChapterData(chapters[chapterId]);  // Set the data for the selected chapter
    }
  }, [chapterId, chapters]);

  if (!chapterData) {
    return <div>Loading chapter content...</div>;
  }

   // Helper function to capitalize the first letter of the topic
 const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const [chapterName, part] = chapterData.chapter.name.split(/\(|\)/);
const contentArray = chapterData.chapter.content.split(/[.|।]/)
.filter(item => item.trim() !== '');


  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };
  const handleNextClick = () => {
    localStorage.setItem('CId',  chapterId );
    localStorage.setItem('Entry',  'chapter');
    navigate('/entry');
  };

  return (
    <div className="container-page">
              {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
          {batch} - {chapterData.subject}
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={{display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
    <div className="card-containerTopic">
      <div className="card-badgeTopic">Topic - {capitalizeFirstLetter(chapterData.chapter.topic)}</div>

      <div className="card-image-containerTopic">
        <div className="card-imageTopic">
         {chapterName} {part && (<span style={{ color: 'yellow'}}>({part.trim()})</span>)} </div>
      </div>

      <div className='card-contentTopic'>
        <h4 className="card-headingTopic" >Defination-</h4>
        <ul className="card-subheadingTopic">
        {contentArray.map((sentence, index) => (
          <li key={index} style={{marginBottom:'5px'}}>
            {sentence.trim()}!
          </li>
        ))}
      </ul>
        
        {/* Display MCQs */}
      {chapterData.chapter.questions && chapterData.chapter.questions.map((question, index) => (
        <div key={index}>
          <p className="card-headingTopic"><strong >Question {index + 1}:</strong> <span style={{color:'#5f6fff'}}> {question.question}</span></p>
          <ul>
            {Object.entries(question.options).map(([optionKey, optionValue], optIndex) => (
              <li key={optIndex}>{optionKey}: {optionValue}</li>
            ))}
          </ul>
          <p style={{color:'green'}}><strong>Answer:</strong> {question.answer}</p>
        </div>
      ))}
      </div>
      <button className="card-buttonTopic" onClick={handleNextClick}>Try Quiz Exams ➜</button>

    </div>
      </div>
    </div>
  );
};

export default ContainerPage;
