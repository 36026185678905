import React, { useEffect, useRef } from 'react';
import { CircularProgress } from '@mui/material';

const ModalComponent = ({
  circularProgress,
  topic,
  description,
  onPrimaryClick,
  onSecondaryClick,
  primaryBtnText,
  secondaryBtnText,
  primaryBtnColor,
  secondaryBtnColor,
  hidePrimary,
  hideSecondary,
  hideModal // This function will hide the modal when clicked outside
}) => {
  const modalRef = useRef(null); // Reference to modal container

  useEffect(() => {
    // Detect clicks outside the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        hideModal(); // Hide modal if clicked outside
      }
    };

    // Add event listener to detect outside clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener when modal is hidden
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hideModal]);

  return (
    <div className="modal-overlay">
      <div className="modal-container" ref={modalRef}>
        {circularProgress && <CircularProgress size={50} />}

        <h3>{topic}</h3>
        <p>{description}</p>

        <div className="modal-buttons">
          {!hidePrimary && (
            <button
              className="modal-button"
              style={{ backgroundColor: primaryBtnColor }}
              onClick={onPrimaryClick}
            >
              {primaryBtnText}
            </button>
          )}
          {!hideSecondary && (
            <button
              className="modal-button"
              style={{ backgroundColor: secondaryBtnColor }}
              onClick={onSecondaryClick}
            >
              {secondaryBtnText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
