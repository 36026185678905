import React, { useEffect, useState, useCallback } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import styles from './ContainerQuiz.module.css'; // Assuming CSS module for styles
import { useAppContext } from '../AppContext';
import useDisableBackButton from '../useDisableBackButton'; // Adjust the path
const batches = ["SSC EXAM", "UPSC EXAM", "POLICE EXAM"];


const QuizPage = () => {
  const [, setBatchData] = useState({});

  useDisableBackButton(); // Disable the browser back/forward buttons
  const navigate = useNavigate();
  const [currentContest, setCurrentContest] = useState(null); // To store contest metadata
  const [questionsData, setQuestionsData] = useState([]); // To store questions data
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isAnswered, setIsAnswered] = useState(false);
  const [timer, setTimer] = useState(0);

  const [index1, setIndex1] = useState('#ba68c8');
  const [index2, setIndex2] = useState('#ba68c8');
  const [index3, setIndex3] = useState('#ba68c8');
  const [index4, setIndex4] = useState('#ba68c8');

  const { showModal, hideModal } = useAppContext();
  useEffect(() => {
    const user = localStorage.getItem('userdata');
    if (user) {
    }else{
      showModal({
        circularProgress: false,
        topic: 'UserID Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');

        }
      });
      return;
    }
  }, [hideModal, navigate , showModal]);

  const fetchContestDataWithGet = useCallback(() => {
    const contestId = localStorage.getItem('CId');
    const user = localStorage.getItem('userdata');
    const userdata = JSON.parse(user);
    const userId = userdata.user;
    if (!contestId) {
      showModal({
        circularProgress: false,
        topic: 'Contest Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');
          localStorage.removeItem(contestId);
        }
      });
      return;
    }
    
    fetch(`https://prizeclasses.in/sql/contestprize.php?contestId=${contestId}&userId=${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          localStorage.setItem(contestId, JSON.stringify(data)); // Ensure data is stored as a JSON string
        
        } else {
          showModal({
            circularProgress: false,
            topic: 'Error',
            description: `Failed to retrieve contest details: ${data.message}`,
            primaryBtnText: 'Retry',
            primaryBtnColor: 'orange',
            hidePrimary: false,
            onPrimaryClick: () => {
              hideModal();
              fetchContestDataWithGet();
            }
          });
          console.error('Error in response:', data.message);
        }
      })
      .catch((error) => {
        showModal({
          circularProgress: false,
          topic: 'Network Error',
          description: 'Unable to reach the server. Please check your connection and try again.',
          primaryBtnText: 'Retry',
          primaryBtnColor: 'orange',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
            fetchContestDataWithGet();
          }

        });
        console.error('Error fetching contest data:', error);
      });
  }, [hideModal,showModal,navigate]);

  useEffect(() => {
    fetchContestDataWithGet();
  }, [fetchContestDataWithGet]);


  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    // Iterate over each batch and check localStorage for data expiry
    batches.forEach((batchName) => {
      const storedData = JSON.parse(localStorage.getItem(batchName));

      if (!storedData || storedData.date !== today) {
        // If no stored data or the date doesn't match today, fetch new data
        fetchBatchData(batchName, today);
      } else {
        // If data exists and date matches, set it in state
        setBatchData(prev => ({
          ...prev,
          [batchName]: storedData
        }));
      }
    });
  }, []);

  const fetchBatchData = (batchName, contestDate) => {
    fetch(`https://prizeclasses.in/Batches/${batchName}/${contestDate}.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Store the fetched data in localStorage with today's date
        const dataWithDate = { ...data, date: contestDate };
        localStorage.setItem(batchName, JSON.stringify(dataWithDate));

        // Update the state with the new data
        setBatchData(prev => ({
          ...prev,
          [batchName]: dataWithDate
        }));

        console.log(`Fetched and stored data for ${batchName}`);
      })
      .catch(error => {
        console.error(`Error fetching data for ${batchName}:`, error);
      });
  };


  // Fetch contest metadata based on contestId (CId) from localStorage
  useEffect(() => {
    const storedContestId = localStorage.getItem('CId'); // Get contestId from localStorage
const storedContests = JSON.parse(localStorage.getItem(storedContestId)); // Parse it back to an object/array

if (storedContestId && storedContests) {
  const foundContest = storedContests.find(
    (contest) => contest.contestId === storedContestId,
  );

  if (foundContest) {
    setCurrentContest(foundContest);
    const batchName = foundContest.batchName;
    console.log('batchName', batchName);

    let batchData = null;
    // Safely attempt to parse the batch data from localStorage
    try {
      const storedBatchData = localStorage.getItem(batchName);
      if (storedBatchData) {
        batchData = JSON.parse(storedBatchData);
      }
    } catch (error) {
      console.error(`Error parsing batch data for ${batchName}:`, error);
    }
    console.log(batchData)
    // Now fetch questions based on batch and date
    if (!batchData && !batchData.date && batchData.date !== foundContest.contestDate) {
      // Either batchData is not there or the stored data is outdated
      console.log(`Fetching batch data for ${batchName} from the server...`);
      
      // Fetch the JSON file based on batch name and date
      fetch(`https://prizeclasses.in/Batches/${batchName}/${foundContest.contestDate}.json`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Store the fetched data in localStorage
          localStorage.setItem(batchName, JSON.stringify(data));
          console.log(`Fetched and stored data for ${batchName}`);

          // Fetch questions based on question numbers
          const questionNumbers = foundContest.questionNumbers.split(',').map(num => parseInt(num, 10)); // Convert question numbers to an array
          const fetchedQuestions = questionNumbers.map(num => data.questions[num]);

          setQuestionsData(fetchedQuestions); // Store the fetched questions
          fetchContestDataWithGet();
        })
        .catch(error => console.error(`Error fetching batch data: ${error}`));
    } else {
      // If batch data is found and is up-to-date, use it directly
      console.log('Using stored batch data:', batchData);
      const questionNumbers = foundContest.questionNumbers.split(',').map(num => parseInt(num, 10)); // Convert question numbers to an array
      const fetchedQuestions = questionNumbers.map(num => batchData.questions[num]);

      setQuestionsData(fetchedQuestions); // Store the fetched questions
    }
  } else {
    showModal({
      circularProgress: false,
      topic: 'Error',
      description: 'No contest found for the given CId.',
      primaryBtnText: 'Retry',
      primaryBtnColor: 'orange',
      hidePrimary: false,
      onPrimaryClick: () => {
        hideModal();
        fetchContestDataWithGet();
      }
    });
  }
} else {
  console.error('CId or contests not found in localStorage.');
}

  }, [fetchContestDataWithGet,hideModal,showModal]);

  const resetColors = () => {
    setIndex1('#ba68c8');
    setIndex2('#ba68c8');
    setIndex3('#ba68c8');
    setIndex4('#ba68c8');
  };

  useEffect(() => {
    resetColors();
  }, [currentQuestionIndex]);

  const handleOptionClick = (optionKey, index) => {
    if (!isAnswered && questionsData.length > 0) {
      let AD = localStorage.getItem('AQ') ? parseInt(localStorage.getItem('AQ'), 10) : 0;
      AD = AD + 1;
      localStorage.setItem('AQ', AD);

      const question = questionsData[currentQuestionIndex];
      if (!question)
      return; // Prevent null/undefined access
      const correctAnswerText = question.answer;
      const selectedAnswerText = question.options[optionKey];

      let reviewData = localStorage.getItem('reviewData')
        ? JSON.parse(localStorage.getItem('reviewData'))
        : [];

      // Prepare the current question's data
      const questionReview = {
        question: question.question,   // Store the actual question text
        options: question.options,     // Store the options for the question
        correctAnswer: correctAnswerText, // Store the correct answer text
        userAnswer: selectedAnswerText  // Store the user's selected answer
      };

      // Add the current question's data to the review array
      reviewData.push(questionReview);

      // Store the updated array back to localStorage
      localStorage.setItem('reviewData', JSON.stringify(reviewData));

      if (selectedAnswerText === correctAnswerText) {
        let CA = localStorage.getItem('CA') ? parseInt(localStorage.getItem('CA'), 10) : 0;
        CA = CA + 1;
        localStorage.setItem('CA', CA);

        if (index === 0) setIndex1('green');
        if (index === 1) setIndex2('green');
        if (index === 2) setIndex3('green');
        if (index === 3) setIndex4('green');
      } else {
        let WA = localStorage.getItem('WA') ? parseInt(localStorage.getItem('WA'), 10) : 0;
        WA = WA + 1;
        localStorage.setItem('WA', WA);

        if (index === 0) setIndex1('red');
        if (index === 1) setIndex2('red');
        if (index === 2) setIndex3('red');
        if (index === 3) setIndex4('red');

        const correctIndex = Object.keys(question.options).find(
          (key) => question.options[key] === correctAnswerText
        );
        if (correctIndex === 'A') setIndex1('green');
        if (correctIndex === 'B') setIndex2('green');
        if (correctIndex === 'C') setIndex3('green');
        if (correctIndex === 'D') setIndex4('green');
      }

      setIsAnswered(true);

      setTimeout(() => {
        if (currentQuestionIndex + 1 < questionsData.length) {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
          setIsAnswered(false);
        } else {
          navigateToResult();
        }
      }, 1000);
    }
  };

  const navigateToResult = useCallback(() => {
    if (!currentContest) return;

    const remainingTime = currentContest.finishTime * 1000 - timer;
    const score =
      parseInt(localStorage.getItem('CA'), 10) * Math.floor(remainingTime / 1000) -
      parseInt(localStorage.getItem('WA'), 10);
      // localStorage.removeItem(currentContest.contestId);

    navigate('/result', {
      state: {
        totalQuestions: questionsData.length,
        points: score,
        timeTaken: timer,
        timeTotal: currentContest.finishTime,
        topic: currentContest.contestType,
      },
    });
  }, [navigate, timer, currentContest, questionsData]);

  useEffect(() => {
    if (currentContest) {
      const countdown = setInterval(() => {
        if (timer < currentContest.finishTime * 1000) {
          setTimer((prevTime) => prevTime + 100);
        } else {
          clearInterval(countdown);
          navigateToResult();
        }
      }, 100);
      return () => clearInterval(countdown);
    }
  }, [timer, navigateToResult, currentContest]);

  if (!currentContest || questionsData.length === 0) {
    return <div>Loading contest or questions...</div>;
  }

  const question = questionsData[currentQuestionIndex];
  const questionOptions = question ? Object.entries(question.options) : [];
  const handleRemoveId = () => {
    console.log('remove this'+currentContest.contestId)
    localStorage.removeItem(currentContest.contestId);
    navigate('/');
  };

  return (
    <>
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#A42FC1' }}>
        <Toolbar>
          <IconButton onClick={() => handleRemoveId()}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            {currentContest.contestType}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={styles.quizScreen}>
        <div className={styles.pageCard}>
          <div className={styles.quizContainer}>
            <div className={styles.save}>We are saving UI</div>
          </div>
          <div className={styles.questionCard}>
            <div className={styles.header}>
              <div className={styles.attendedQuestions}>Q. {currentQuestionIndex + 1}</div>
              <div className={styles.circularProgress}>
                <div className={styles.progressCircle}>
                  <span className={styles.currentQuestion}>{timer / 1000} s</span>
                </div>
              </div>
              <div className={styles.totalQuestions}>Q. {questionsData.length}</div>
            </div>

            <h3 className={styles.questionText}>{question?.question}</h3>
          </div>

          <div className={styles.optionsContainer}>
            {questionOptions.map(([optionKey, optionValue], index) => (
              <button
                key={index}
                style={{
                  backgroundColor:
                    index === 0
                      ? index1
                      : index === 1
                      ? index2
                      : index === 2
                      ? index3
                      : index === 3
                      ? index4
                      : '#F3E5F5',
                  width: '85%',
                  minHeight: '50px',
                  padding: '10px',
                  border: '2px solid #fff9c4',
                  borderRadius: '10px',
                  color: 'white',
                  fontSize: '16px',
                }}
                onClick={() => handleOptionClick(optionKey, index)}
                disabled={isAnswered}
              >
                {optionValue}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizPage;
