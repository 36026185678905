import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';

function TransactionList() {
  const navigate = useNavigate();
  const { showModal, hideModal } = useAppContext();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/wallet');
  };

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const userdata = localStorage.getItem('userdata');
    
    if (userdata) {
      const user = JSON.parse(userdata);

    // Fetch data from the PHP endpoint
    fetch(`https://prizeclasses.in/sql/transaction.php?userId=${user.UserId}`)
      .then((response) => response.json())
      .then((data) => setTransactions(data))
      .catch((error) => console.error('Error fetching data:', error));
      // console.log(transactions)
     
    }else{
      showModal({
        circularProgress: false,
        topic: 'Account Error ',
        description: 'User not found. Please login /Signup to continue.',
        primaryBtnText: 'Login',
        primaryBtnColor: 'green',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login')
        }
      });   

    }
  }, [hideModal,showModal,navigate]);
  
  return (
    <>
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Transaction List
          </Typography>
        </Toolbar>
      </AppBar>

  <div style={{ margin: '10px' }} className='chapter-list'>

        {transactions.length === 0?<div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center',alignContent:'center',height:'70vh'}}><div className='loading'>No transaction history</div></div>:''}

        {transactions.length !== 0? transactions.map((transaction) => {
          return (
            <div key={transaction.transaction_id} className="card-container">
              <div className="pcard-badgebox">
                <div className="pcard-left">#{transaction.productId}
                </div>
                <div className="pcard-badge">                    {transaction.product}
                </div>
              </div>

              <div className="flex">
                <div className="bar">
                  <p style={{ fontSize: '13px' }}>Amount</p>
                  <span style={{ fontWeight: '900', fontSize: '22px', marginBottom: '5px' }}>
                    ₹{transaction.amount}
                  </span>
                  {/* <span style={{ fontWeight: '700', fontSize: '16px', color: 'blue' }}>
                    {transaction.product}
                  </span> */}
                </div>

                <div className="barR">
                  <p style={{ margin: 0, fontSize: '13px' }}>Status</p>
                  <p style={{ margin: 0, fontSize: '16px',fontWeight:'bolder', color: 'green' }}>{transaction.status}</p>
                </div>

                <div className="barR">
                  <span className="pcard-left">Payment Method</span>
                  <span>{transaction.payment_method}</span>
                  {/* <br/> */}
                  {/* <span>{transaction.date}</span> */}
                </div>
              </div>
              
              <div className="pcard-box" style={{ color: 'blue', textAlign: 'center', width: '100%', height: '35px', fontWeight: '600' }}>
                <div className="pcard-left">
                <span>{transaction.date}</span>
               </div>
                <div className="pcard-left">{transaction.transaction_type}</div>
              </div>
            </div>
          );
        }):''}
      </div>
    </>
  );
}

export default TransactionList;
