import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

function NotificationsPage() {
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div>
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Terms & Conditions
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Space to prevent content from being overlapped by AppBar */}
      
      <Box>
      <div class="section">
        <h1>Terms and Conditions</h1>
        <p><strong>Last Updated:</strong> 24/09/2024</p>
        <p>Welcome to <strong>PriceClasses</strong> ("PriceClasses," "we," "us," or "our"). These Terms and Conditions ("Terms") govern your use of our website and services, including any content, features, and functionality offered on or through <strong>PriceClasses</strong>. By accessing or using our platform, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree with any part of these Terms, you must immediately stop using our services.</p>
        <p>Please read these Terms carefully before engaging in any activities on <strong>PriceClasses</strong>.</p>
    </div>

    <div class="section">
        <h2>1. Eligibility</h2>
        <h3>a. General Requirements</h3>
        <p>By using <strong>PriceClasses</strong>, you represent that:</p>
        <ul>
            {/* <li>You are at least 18 years of age or the legal age of majority in your jurisdiction.</li>
            <li>If you are under 18, you must have parental or guardian consent to use the platform.</li> */}
            <li>You have the authority and capacity to enter into these Terms, which are legally binding.</li>
            <li>You are not prohibited from accessing or using this site under any applicable laws, regulations, or rules.</li>
        </ul>
        <h3>b. Educational and Entertainment Purpose</h3>
        <p>PriceClasses offers a unique combination of educational quizzes and learning contests designed to promote intellectual growth. While our services include contests similar to those in the online fantasy sports space, they are strictly educational and should not be mistaken for gambling or betting. Your participation in any contest, quiz, or competition on PriceClasses must solely be for educational and entertainment purposes.<strong>PrizeClasses</strong> is completely based on learning skill and ability of students/players.</p>

        {/* <h3>c. Compliance with Local Laws</h3>
        <p>It is your responsibility to ensure that you comply with any local, national, or international laws that apply to your use of <strong>PriceClasses</strong>. You understand that some jurisdictions may restrict the use of websites like ours, particularly those involving contests and quizzes. If such laws exist in your location, you agree not to use the platform.</p> */}
    </div>

    <div class="section">
        <h2>2. Account Registration and User Responsibilities</h2>
        <h3>a. Account Creation</h3>
        <p>To access certain features of our platform, including quizzes and contests, you must register for an account by providing accurate, current, and complete information. You agree to update your information as needed to ensure its accuracy. You are solely responsible for:</p>
        <ul>
            <li>Maintaining the confidentiality of your account credentials, including your username and password.</li>
            <li>All activities that occur under your account, whether authorized or unauthorized.</li>
        </ul>
        <p>If you believe your account has been compromised, you must immediately notify us at <strong>support@priceclasses</strong>.</p>

        <h3>b. Account Termination or Suspension</h3>
        <p>PriceClasses reserves the right to suspend or terminate your account at our sole discretion, without notice, if we determine that you have violated these Terms, engaged in fraudulent activity, or engaged in behavior that harms the platform or other users. You acknowledge that PriceClasses is not responsible for any loss or damage arising from your failure to comply with the account security obligations outlined above.</p>
    </div>

    <div class="section">
        <h2>3. Use of the Platform</h2>
        <h3>a. Permitted Use</h3>
        <p>You agree to use <strong>PriceClasses</strong> only for lawful purposes and in accordance with these Terms. You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the platform for personal, non-commercial use.</p>

        <h3>b. Prohibited Conduct</h3>
        <p>You may not engage in any conduct that is illegal, harmful, or detrimental to the platform, its users, or its reputation. Specifically, you agree not to:</p>
        <ul>
            <li>Use <strong>PriceClasses</strong> in any way that violates local, national, or international law.</li>
            <li>Upload, transmit, or share any material that is defamatory, obscene, offensive, or harmful.</li>
            <li>Exploit the platform for commercial purposes without express authorization from PriceClasses.</li>
            <li>Circumvent, disable, or interfere with any security features of the platform.</li>
            <li>Use automated tools, scripts, or bots to access, monitor, or interact with the platform.</li>
            <li>Misrepresent your identity or affiliation with any person or organization.</li>
        </ul>
        <p>Violation of these prohibitions may result in immediate account suspension or termination and could subject you to civil or criminal penalties.</p>
    </div>

    <div class="section">
        <h2>4. Learning Quizzes and Contests</h2>
        <h3>a. Participation in Quizzes</h3>
        <p>PriceClasses offers educational quizzes designed to test your knowledge in various subjects. By participating in a quiz, you acknowledge that:</p>
        <ul>
            <li>The quiz results are based on your knowledge and effort.</li>
            <li>Your participation is voluntary, and there are no guarantees of winning rewards or prizes.</li>
            <li>You will not engage in unfair practices, such as using unauthorized aids or tools to manipulate your performance.</li>
        </ul>

        <h3>b. Contests and Rewards</h3>
        <p>From time to time, PriceClasses may offer learning contests where users can earn rewards or prizes based on their performance. Participation in such contests is governed by the following rules:</p>
        <ul>
            <li><strong>Eligibility:</strong> To be eligible for rewards, you must meet the contest's specific eligibility criteria, such as age or location restrictions.</li>
            <li><strong>Winnings:</strong> Any prizes or rewards earned are subject to verification and compliance with the contest rules. PriceClasses reserves the right to withhold or revoke prizes if there is any evidence of cheating or rule violations.</li>
            <li><strong>Taxes and Legal Compliance:</strong> You are solely responsible for reporting and paying any applicable taxes on your winnings or rewards as required by your local laws.</li>
            <li><strong>Prize Delivery:</strong> Physical or monetary rewards may require additional information, such as bank details or a mailing address, to facilitate delivery. PriceClasses is not responsible for delays or errors in prize delivery caused by incorrect information provided by you.</li>
        </ul>

        <h3>c. Contest Cancellation</h3>
        <p>PriceClasses reserves the right to cancel, suspend, or modify any quiz or contest at any time without prior notice if we believe there is any tampering, fraud, or other issues that affect the integrity of the contest.</p>
    </div>

    <div class="section">
        <h2>5. Payment and Subscription Services</h2>
        <h3>a. Premium Content and Services</h3>
        <p>While many aspects of <strong>PriceClasses</strong> are available for free, certain features, quizzes, or services may require payment. If you choose to access premium content, you agree to pay the associated fees.</p>

        <h3>b. Payment Processing</h3>
        <p>All payments made through <strong>PriceClasses</strong> are processed by secure third-party payment providers. By submitting payment information, you authorize our payment providers to charge your account for the applicable fees, including any taxes or processing charges.</p>

        <h3>c. Refund Policy</h3>
        <p>At PrizeClasses, we are dedicated to providing a seamless and enjoyable experience for all our participants. To ensure clarity and transparency, we have outlined our refund policy below. Please read it carefully before participating in any contests or quizzes.</p>
        <h4>1. No Cash Refunds</h4>

        <p>We regret to inform you that we do not offer cash refunds for any entries into our contests or quizzes. Once a participant submits their entry, it is considered final and non-refundable. This policy allows us to maintain the integrity and structure of our competitions.</p>

        <h4>2. Conditions for Wallet Refunds</h4>

        <p> While we do not provide cash refunds, we do offer wallet refunds under specific circumstances:</p>

        <p><strong>- Suspension of Quizzes: </strong>If a quiz is suspended due to insufficient participants (fewer than the minimum required) the refund will be processed within 24 hour .</p>

        <p><strong>- Notification:</strong> Participants will be notified via email or in-app notification if a quiz is suspended and refunds are being processed.</p>

        <h4>3. Participant Responsibility</h4>

        <p>It is important to note that each participant is responsible for their own engagement in contests:</p>

        <p><strong>- Joining on Time: </strong>Participants must ensure they join the contest before the scheduled start time. If you fail to join the contest for any reason, including technical difficulties or personal commitments, no refunds will be issued.</p>

        <p><strong>- Technical Issues:</strong> While we strive to provide a reliable platform, technical issues can occur. Participants are encouraged to check their internet connectivity and ensure they are logged in before the start of any quiz.</p>

        <h4>4. Finality of Policy</h4>

        <p>By participating in our contests and quizzes, you acknowledge and agree to this refund policy. We encourage you to reach out with any questions or concerns prior to entering a contest.</p>

        <h3>d. Unauthorized Transactions</h3>
        <p>If you believe that an unauthorized transaction has occurred in connection with your account, please contact us immediately at <strong>support@priceclasses</strong>. You are solely responsible for any unauthorized use of your account resulting from your failure to safeguard your login credentials.</p>
    </div>
    <div class="section">
        <h2>6. Intellectual Property Rights</h2>

        <h3>a. Ownership of Content</h3>
        <p>All content on <strong>PriceClasses</strong>, including but not limited to text, graphics, logos, images, audio, video, quizzes, learning materials, and software (collectively "Content"), is the property of PriceClasses or its licensors and is protected by copyright, trademark, and other intellectual property laws.</p>

        <p>You agree not to reproduce, distribute, display, or otherwise use any of the Content for any commercial purpose without the express written consent of PriceClasses.</p>

        <h3>b. User-Generated Content</h3>
        <p>PriceClasses allows users to contribute content, including comments, reviews, feedback, or quiz submissions ("User Content"). By submitting User Content to <strong>PriceClasses</strong>, you grant us a worldwide, royalty-free, perpetual, irrevocable, and non-exclusive license to use, reproduce, modify, distribute, and display such content in connection with our platform and services.</p>

        <p>You represent and warrant that you have all necessary rights to submit User Content and that such content does not infringe on any third party's intellectual property rights or violate any laws.</p>

        <h3>c. Content Removal</h3>
        <p>PriceClasses reserves the right to remove any User Content that, in our sole discretion, violates these Terms or is deemed harmful, defamatory, or inappropriate.</p>
    </div>

    <div class="section">
        <h2>7. Privacy and Data Protection</h2>

        <p>Your privacy is important to us. By using <strong>PriceClasses</strong>, you consent to the collection, use, and disclosure of your personal information as described in our <a href="https://www.priceclasses/privacy-policy">Privacy Policy</a>. You understand and agree that PriceClasses may collect, store, and use your data for the following purposes:</p>
        <ul>
            <li>Providing you with access to quizzes, learning materials, and contests.</li>
            <li>Analyzing user behavior to improve our services.</li>
            <li>Sending marketing communications (which you can opt out of).</li>
            <li>Complying with legal requirements.</li>
        </ul>

        <p>PriceClasses takes appropriate measures to secure your personal information. However, no system is entirely secure, and you acknowledge that you use the platform at your own risk.</p>
    </div>

    <div class="section">
        <h2>8. Disclaimers and Limitation of Liability</h2>

        <h3>a. No Warranty</h3>
        <p>PriceClasses makes no warranties or representations regarding the accuracy, reliability, or availability of the platform. Our services are provided "as-is" and "as available" without any warranty, express or implied. To the maximum extent permitted by law, we disclaim all warranties, including but not limited to:</p>
        <ul>
            <li>Fitness for a particular purpose</li>
            <li>Non-infringement</li>
            <li>Accuracy of information</li>
            <li>Uninterrupted or error-free service</li>
        </ul>

        <h3>b. Limitation of Liability</h3>
        <p>To the fullest extent permitted by law, PriceClasses, its directors, employees, affiliates, and partners shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from:</p>
        <ul>
            <li>Your use or inability to use the platform.</li>
            <li>Any errors, omissions, or inaccuracies in the content.</li>
            <li>Any bugs, viruses, or harmful components transmitted through the platform.</li>
        </ul>

        <p>In no event shall our liability exceed the amount paid by you, if any, for accessing the platform's services.</p>
    </div>

    <div class="section">
        <h2>9. Indemnification</h2>

        <p>You agree to indemnify, defend, and hold harmless PriceClasses, its officers, directors, employees, affiliates, and partners from and against any claims, liabilities, damages, losses, or expenses, including legal fees, arising out of or in connection with your use of the platform, violation of these Terms, or infringement of any third party's rights.</p>
    </div>

    <div class="section">
        <h2>10. Governing Law and Dispute Resolution</h2>

        <h3>a. Governing Law</h3>
        <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of india, without regard to its conflict of law principles.</p>

        <h3>b. Dispute Resolution</h3>
        <p>In the event of any dispute arising out of or relating to these Terms or your use of <strong>PriceClasses</strong>, you agree to first attempt to resolve the dispute informally by contacting us at <strong>support@priceclasses</strong>.</p>

        <p>If the dispute cannot be resolved informally, you agree to submit to the exclusive jurisdiction of the courts of india for the resolution of any legal claims.</p>
    </div>

    <div class="section">
        <h2>11. Changes to These Terms</h2>

        <p>PriceClasses reserves the right to update or modify these Terms at any time, with or without prior notice. Any changes will become effective immediately upon posting on our platform. Your continued use of <strong>PriceClasses</strong> following any such changes indicates your acceptance of the revised Terms.</p>

        <p>We recommend reviewing these Terms periodically to stay informed about any updates or changes.</p>
    </div>

    <div class="section">
        <h2>12. Contact Information</h2>

        <p>If you have any questions or concerns about these Terms and Conditions, please contact us at:</p>

        <ul>
            <li><strong>Name:</strong> Sandesh Roshan</li>
            <li><strong>Email:</strong> support@prizeclasses</li>
            <li><strong>Address:</strong> 47A/8M, North Loakpur,Nani,Prayagraj,Uttar Pardesh </li>
            <li><strong>Phone:</strong> 6390298282</li>
        </ul>
    </div>

    <div class="section">
        <h2>Conclusion</h2>
        <p>By accessing or using <strong>PriceClasses</strong>, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms, you must discontinue your use of the platform immediately.</p>
    </div>
      </Box>
    </div>
  );
}

export default NotificationsPage;
