import React, { createContext, useState, useEffect } from 'react';

// Create a context for batches
export const BatchContext = createContext();

export const BatchProvider = ({ children }) => {
  const [batch, setBatch] = useState('SSC');  // Default batch is SSC
  const [chapters, setChapters] = useState([]);  // Store fetched chapters
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null);

  // Function to get today's date in 'YYYY-MM-DD' format
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];  // Return 'YYYY-MM-DD'
  };

  // Function to check if data for the current batch is already stored and valid (compare dates)
  const isDataValid = (storedData) => {
    if (!storedData) return false;

    const today = getTodayDate();
    const { filename, data } = storedData;  // Assume stored data contains filename and data
    return filename === today && data.date === today;  // Check both filename and JSON date
  };

  // Fetch chapters based on the selected batch and store in localStorage if not present or outdated
  const fetchChapters = async (selectedBatch) => {
    const today = getTodayDate();
    const localStorageKey = `batchData_${selectedBatch}`;  // Unique key for each batch

    // Check if the data exists in localStorage
    const storedData = JSON.parse(localStorage.getItem(localStorageKey));

    if (storedData && isDataValid(storedData)) {
      console.log(`Using cached data for ${selectedBatch}`);
      setChapters(storedData.data.subjects);  // Use cached data
      setLoading(false);
    } else {
      console.log(`Fetching data for ${selectedBatch} from the server...`);
      setLoading(true);
      const url = `https://prizeclasses.in/Batches/${selectedBatch}/${today}.json`;  // URL based on today's date

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch chapters');
        }
        const data = await response.json();
        setChapters(data.subjects);  // Set the chapters

        // Store the fetched data in localStorage along with the current date as filename
        localStorage.setItem(localStorageKey, JSON.stringify({
          filename: today,  // Store the date (filename) for comparison
          data: data,  // Store the JSON data itself
        }));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }
  };

  // Update chapters whenever the batch changes
  useEffect(() => {
    fetchChapters(batch);
  }, [batch]);

  return (
    <BatchContext.Provider value={{ batch, setBatch, chapters, loading, error }}>
      {children}
    </BatchContext.Provider>
  );
};
