import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

function NotificationsPage() {
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div>
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            About Us
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Space to prevent content from being overlapped by AppBar */}
      
      <Box>
      <div class="section">
    <h1>About Us</h1>
    <p><strong>Last Updated:</strong> 24/09/2024</p>
    <p>Welcome to <strong>PrizeClasses</strong>, your trusted companion in competitive exam preparation. We specialize in providing coaching for <strong>SSC, UPSC, and Police exams</strong>, helping students achieve their educational goals with well-rounded support.</p>
</div>

<div class="section">
    <h2>Our Mission</h2>
    <p>We are committed to fostering academic excellence by delivering <strong>quality education and practical learning experiences</strong>. Our goal is to empower students with the knowledge and confidence to excel in their chosen fields and build a brighter future.</p>
</div>

<div class="section">
    <h2>What We Offer</h2>
    <ul>
        <li>Comprehensive study materials, PDFs, and mock tests tailored to the latest exam patterns.</li>
        <li>Personalized coaching for SSC, UPSC, and Police exams.</li>
        <li>Simulated exam conditions to enhance your preparation and build confidence.</li>
    </ul>
</div>

<div class="section">
    <h2>Contest Opportunities</h2>
    <p>At PrizeClasses, we regularly host <strong>contests and challenges</strong> to motivate students and track their progress. These competitions provide a platform to:</p>
    <ul>
        <li>Test knowledge in a competitive environment.</li>
        <li>Experience real exam-like pressure.</li>
        <li>Win <strong>exciting prizes and scholarships</strong> for outstanding performance.</li>
        <li>our <strong>Prizing range starts from 5  to 1000 rupees</strong>. Try now</li>

    </ul>
    <p>Our contests promote continuous learning and create a dynamic environment for students to excel.</p>
</div>

<div class="section">
    <h2>Join Us</h2>
    <p>Become part of an inspiring community where preparation meets opportunity. With PrizeClasses by your side, success is just a step away!</p>
</div>

      </Box>
    </div>
  );
}

export default NotificationsPage;
