import React, { createContext, useState, useContext } from 'react';
import ModalComponent from './AlertMobile';
export const AppContext = createContext();

export const useAppContext = () => {
    return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
    // Define default modal data
    const defaultModalData = {
        isVisible: false,
        circularProgress: false,
        topic: '',
        description: '',
        onPrimaryClick: null,
        onSecondaryClick: null,
        primaryBtnText: 'OK',
        secondaryBtnText: 'Not Now',
        primaryBtnColor: 'green',
        secondaryBtnColor: 'white',
        hidePrimary: true,
        hideSecondary: true,
        identifier: null,
    };

    const [modalData, setModalData] = useState(defaultModalData);
    const [retryActions, setRetryActions] = useState({});

    // Function to show modal and merge the provided data with default values
    const showModal = (data) => {
        if (retryActions[data.identifier]) {
            return;
        }

        setModalData({ ...defaultModalData, ...data, isVisible: true });
    };

    const hideModal = () => {
        setModalData({ ...modalData, isVisible: false });
    };

    const handlePrimaryClick = () => {
        if (modalData.primaryBtnText === 'Retry') {
            setRetryActions((prevState) => ({
                ...prevState,
                [modalData.identifier]: true,
            }));
        }

        if (modalData.onPrimaryClick) {
            modalData.onPrimaryClick();
        }

        hideModal();
    };

    return (
        <AppContext.Provider value={{ modalData, showModal, hideModal }}>
            {children}
            {modalData.isVisible && (
                <ModalComponent
                    {...modalData}
                    onPrimaryClick={handlePrimaryClick}
                    hideModal={hideModal} // Pass the hideModal function
                />
            )}
        </AppContext.Provider>
    );
};
