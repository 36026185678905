import React, { useEffect, useState, useMemo,useCallback } from 'react';
import styles from './EntryPage.module.css'; // CSS Module
import { AppBar, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import useDisableBackButton from '../useDisableBackButton'; // Adjust the path
const batches = ["SSC EXAM", "UPSC EXAM", "POLICE EXAM"];


const EntryPage = () => {
  const { showModal, hideModal } = useAppContext();

  useDisableBackButton(); // Disable the browser back/forward buttons
  const [, setBatchData] = useState({});
  const navigate = useNavigate();

  const [timer, setTimer] = useState(6); // 60 seconds countdown timer
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [selectedOption,] = useState(null);

  localStorage.setItem('AQ', '0');
  localStorage.setItem('AD', '0');
  localStorage.setItem('CA', '0');
  localStorage.setItem('WA', '0');
  localStorage.setItem('reviewData', '');

  // Mock data array for display
  const contentArray = [
    'Prepare for the upcoming quiz',
    'Revise your previous chapters',
    'Make sure you are ready!',
    'Focus on your strengths',
    'Stay calm and do your best',
  ];

  const Entry = useMemo(() => localStorage.getItem('Entry'), []);

  // Countdown timer logic
  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      // When timer ends, redirect to the quiz screen
      if (Entry === 'chapter') {
        navigate('/containerQuiz');
      } else {
        navigate('/containerQuiz2');
      }
    }
  }, [timer, navigate, Entry]);

  // Periodic content change every 2 seconds
  useEffect(() => {
    const contentChangeInterval = setInterval(() => {
      setCurrentContentIndex((prevIndex) => (prevIndex + 1) % contentArray.length);
    }, 2000);
    return () => clearInterval(contentChangeInterval);
  }, [contentArray.length]);

  const buttons = [
    {
      options: Entry === 'chapter' ? ['Cancel'] : ['Cancel'],
    },
    // Add more buttons here
  ];

  // Handle option click logic
  const handleOptionClick = (index) => {
    navigate('/'); // Navigate to home when 'chapter' and first option is clicked
    return
  };

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    // Iterate over each batch and check localStorage for data expiry
    batches.forEach((batchName) => {
      const storedData = JSON.parse(localStorage.getItem(batchName));

      if (!storedData || storedData.date !== today) {
        // If no stored data or the date doesn't match today, fetch new data
        fetchBatchData(batchName, today);
      } else {
        // If data exists and date matches, set it in state
        setBatchData(prev => ({
          ...prev,
          [batchName]: storedData
        }));
      }
    });
  }, []);

  const fetchBatchData = (batchName, contestDate) => {
    fetch(`https://prizeclasses.in/Batches/${batchName}/${contestDate}.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Store the fetched data in localStorage with today's date
        const dataWithDate = { ...data, date: contestDate };
        localStorage.setItem(batchName, JSON.stringify(dataWithDate));

        // Update the state with the new data
        setBatchData(prev => ({
          ...prev,
          [batchName]: dataWithDate
        }));

        console.log(`Fetched and stored data for ${batchName}`);
      })
      .catch(error => {
        console.error(`Error fetching data for ${batchName}:`, error);
      });
  };


  const fetchContestDataWithGet = useCallback(() => {
    const contestId = localStorage.getItem('CId');
  const user = localStorage.getItem('userdata')?localStorage.getItem('userdata'):'false';
    if (user ==='false') {
      navigate('/login');
      showModal({
        circularProgress: false,
        topic: 'Contest Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          localStorage.removeItem(contestId);
        }
      });
      return;
    }
      
  const userdata = JSON.parse(user)
  const userId = userdata.user;
    
    
    if (!contestId) {
      showModal({
        circularProgress: false,
        topic: 'Contest Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');
          localStorage.removeItem(contestId);
        }
      });
      return;
    }
    
    fetch(`https://prizeclasses.in/sql/contestprize.php?contestId=${contestId}&userId=${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          localStorage.setItem(contestId, JSON.stringify(data)); // Ensure data is stored as a JSON string
        
        } else {
          showModal({
            circularProgress: false,
            topic: 'Error',
            description: `Failed to retrieve contest details: ${data.message}`,
            primaryBtnText: 'Retry',
            primaryBtnColor: 'orange',
            hidePrimary: false,
            onPrimaryClick: () => {
              hideModal();
              fetchContestDataWithGet();
            }
          });
          console.error('Error in response:', data.message);
        }
      })
      .catch((error) => {
        showModal({
          circularProgress: false,
          topic: 'Network Error',
          description: 'Unable to reach the server. Please check your connection and try again.',
          primaryBtnText: 'Retry',
          primaryBtnColor: 'orange',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
            fetchContestDataWithGet();
          }
        });
        console.error('Error fetching contest data:', error);
      });
  }, [hideModal,showModal,navigate]);

  useEffect(() => {
    if (Entry !== 'chapter') {
      fetchContestDataWithGet();
    }}, [fetchContestDataWithGet,Entry]);

  return (
    <>
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#A42FC1', alignItems: 'center' }}>
        <Toolbar>
          <Typography variant="h5" style={{ color: 'white', marginLeft: '8px' }}>
            Preapering For Quiz
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={styles.quizScreen}>
        <div className={styles.pageCard}>
          <div className={styles.quizContainer}>
            <div className={styles.circularProgress}>
              <div className={styles.progressCircle}>
                <span className={styles.progressText}>
                  Starts in<br />00:{timer < 10 ? `0${timer}` : timer}
                </span>
              </div>
            </div>
          </div>

          {/* Step 2: Button Card */}
          <div className={styles.questionCard}>
            {/* Step 1: Purple Background with Corner Radius */}
            <div className={styles.header}>
              <h4>{contentArray[currentContentIndex]}</h4>
            </div>
          </div>

          {/* Step 4: Options with Radio Buttons */}
          <div className={styles.optionsContainer}>
            {buttons[0].options.map((option, index) => (
              <button
                key={index}
                className={`${styles.optionButton} ${selectedOption === index ? styles.selectedOption : ''}`}
                onClick={() => handleOptionClick(index)}
              >
                <span className={styles.optionText}>{option}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EntryPage;
