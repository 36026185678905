// components/LayoutWithAds.js
import React from 'react';
import { useMediaQuery } from '@mui/material';
import './LayoutWithAds.css'; // Ensure to adjust the styling here

const LayoutWithAds = ({ children }) => {
  const isMobile = useMediaQuery('(max-width:6000px)'); // Check if the screen is mobile size

  return (
    <div className="layout-container">
      {/* Only show ads if not on mobile */}
      {!isMobile && (
        <div className="left-sidebar" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
          {/* Replace this with actual ad components */}
          <div>
          {/* <p>Sponsered Ads</p> */}
          <img src='https://m.media-amazon.com/images/I/71w4NmnlZqL._SL1500_.jpg' alt='SSC BOOOKS' style={{width:'100%'}}></img>
          <h4>SSC latest General Studies 6500+ TCS MCQ Chapter Wise 6th edition with detailed explanation english medium </h4>
          <a href='https://www.amazon.in/General-Studies-Chapter-detailed-explanation/dp/B0CV5FCKRX/ref=sr_1_5?crid=G9DVHYATDTNX&dib=eyJ2IjoiMSJ9.NtO4xm3QAskwlsO-vJWacWMDh-IEd61LrotreXAeBoFXXNEts9ZkDLQGV8mHEJvMkzv0hiemcFCXMx6jQqhqy25uLgAbwT2zXVJdZVfTR-Km_e2GyQ4SNM5cN26sPNR-m3_PY3mu5ZMpAzuArlL5WOsMgTjwB0huytBZaY155PhXgtt4r_amg8ZW-PMWFJyRSZvP0IetLq5-yyEB2CY7jIVeR9WUk2Ygr265Y5EAqAo.TydoAkWSNdxYPdMMGPjeK3UqVQdMwXrqvn-we6m5-Vk&dib_tag=se&keywords=ssc+books&qid=1729139281&sprefix=ssc+b%2Caps%2C247&sr=8-5'>   <button className='Add'> Buy Now</button></a>
          <br/>
          <br/>

          <img src='https://m.media-amazon.com/images/I/71pnB5hlsJL._SL1500_.jpg' style={{width:'100%'}} alt='SSC CGL Book'></img>
          <h4>20 New Pattern Practice Sets for SSC CHSL 10+2 Tier II Exam with 2 Online Tests | 18 in Book + 2 Online | Staff Selection Commission Combined Higher Secondary Level |Mock Tests for</h4>
          <a href='https://www.amazon.in/Practice-Selection-Commission-Combined-Secondary/dp/9395985720/ref=sr_1_1_sspa?crid=G9DVHYATDTNX&dib=eyJ2IjoiMSJ9.d_LawiZggg6-xRV4PBNG4WTLLVbkkvcWwGyEcjoXLAdXXNEts9ZkDLQGV8mHEJvMkzv0hiemcFCXMx6jQqhqy25uLgAbwT2zXVJdZVfTR-Km_e2GyQ4SNM5cN26sPNR-m3_PY3mu5ZMpAzuArlL5WOsMgTjwB0huytBZaY155PhXgtt4r_amg8ZW-PMWFJyRSZvP0IetLq5-yyEB2CY7jHTUkmqd2ak9uNm5Y6eJxM0.5reYSGXrjRUW386OHSbVX6YHmk6apmPEF3T9-TO_KwA&dib_tag=se&keywords=ssc+books&qid=1729134264&sprefix=ssc+b%2Caps%2C247&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1'>   <button className='Add'> Buy Now</button></a>
  
        </div>
        </div>
      )}
      <div className={`main-content ${isMobile ? 'full-width' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default LayoutWithAds;


