import React, { useRef, useMemo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './ResultPage.module.css'; 
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppContext } from '../AppContext';
import useDisableBackButton from '../useDisableBackButton'; 

const ResultPage = () => {
  const [profileData, setProfileData] = useState(null);  
  const [update, setUpdate] = useState(true);  // Manage update state
  const isUploading = useRef(false);  // Track upload status

  useDisableBackButton();  
  const navigate = useNavigate();
  const location = useLocation();
  const { showModal, hideModal } = useAppContext();
  const Entry = localStorage.getItem('Entry');

  // Extracting state data from previous page
  const { totalQuestions, points, timeTaken, topic,timeTotal } = location.state || {};

  useEffect(() => {
    const userdata = localStorage.getItem('userdata');
    if (userdata) {
      const user = JSON.parse(userdata);
      setProfileData(user);  
    }
  }, []);

  const contestId = localStorage.getItem('CId');
  const correctAnswer = localStorage.getItem('CA');
  const wrongAnswer = localStorage.getItem('WA');
  const replyedAnswer = localStorage.getItem('AQ');

  // Memoize postData to optimize rendering
  const postData = useMemo(() => ({
    contestId,
    userId: profileData?.UserId,
    points,
    time: timeTaken,
    timeout: timeTotal,
    totalAnswer: totalQuestions,
    correctAnswer,
    wrongAnswer,
    replyedAnswer,
  }), [
    contestId, profileData?.UserId, points, timeTaken,timeTotal,
    totalQuestions, correctAnswer, wrongAnswer, replyedAnswer
  ]);

  // Upload data function triggered by button click
  const uploadData = useCallback(async () => {
    if (isUploading.current) return;  // Prevent multiple uploads
    isUploading.current = true;

    showModal({
      circularProgress: true,
      topic: 'Submitting Result...',
      description: 'Please wait...',
    });

    try {
      const response = await fetch('https://prizeclasses.in/sql/result.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
      });

      if (response.status === 200) {
        showModal({
          circularProgress: false,
          topic: 'Answer Submitted',
          description: 'Your answer was successfully submitted!',
          hidePrimary: false,
          primaryBtnText: 'OK',
          onPrimaryClick: () => hideModal(),
        });
        setUpdate(false);
      } else {
        showModal({
          circularProgress: false,
          topic: 'Submission Failed',
          description: 'Please check your internet connection and try again.',
          hidePrimary: false,
          primaryBtnText: 'OK',
          onPrimaryClick: () => hideModal(),
        });
      }
    } catch (error) {
      hideModal();  // Handle network or server errors
    } finally {
      isUploading.current = false;
    }
  }, [postData, showModal, hideModal]);

  return (
    <>
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#A42FC1' }}>
        <Toolbar>
          <IconButton onClick={() => navigate('/')}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            {topic}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={styles.quizScreen}>
        <div className={styles.pageCard}>
          <div className={styles.quizContainer}>
            <div className={styles.circularProgress}>
              <div className={styles.progressCircle}>
                <span className={styles.progressText}>Your score<br />{points} Pts</span>
              </div>
            </div>
            <span style={{ color: 'yellow', fontWeight: 'bold' }}>
            {timeTotal <= timeTaken / 1000
              ? `Timeout: ${timeTaken / 1000} Sec Limit Exceeded!!`
              : `Time: ${timeTaken / 1000} Sec`}
          </span>

          </div>

          <div className={styles.questionCard}>
            <div className={styles.header}>
              <div style={{ color: 'blue', fontWeight: 'bold' }}>
                {replyedAnswer}<br />Attended
              </div>
              <div style={{ color: 'violet', fontWeight: 'bold' }}>
                {totalQuestions}<br />Total Questions
              </div>
            </div>
            <div className={styles.header}>
              <div className={styles.attendedQuestions}>{correctAnswer}<br />Correct</div>
              <div className={styles.totalQuestions}>{wrongAnswer}<br />Wrong</div>
            </div>
          </div>

          <div className={styles.optionsContainer}>
            <button
              className={styles.optionButton2}
              onClick={uploadData}
              style={{ display: Entry === 'Contest' ? 'block' : 'none' }}
            >
              <span className={styles.optionText}>Submit Answer</span>
            </button>
            <button
              className={styles.optionButton}
              onClick={() => navigate('/ranking')}
              style={{ display: Entry === 'Contest' ? 'block' : 'none' }}
            >
              <span style={{fontWeight:update==='red'?'900':'900'}} className={styles.optionText} >Ranking</span>
            </button>
            <button className={styles.optionButton} onClick={() => navigate('/review')}>
              <span className={styles.optionText}>Review</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultPage;
