import React, { useState, useEffect, useCallback } from 'react';
import { useAppContext } from '../AppContext';

const PracticePage = () => {
  const [pdfData, setPdfData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showModal, hideModal } = useAppContext();

  const plan = localStorage.getItem('plan')?localStorage.getItem('plan'):'Standard'
  const fetchPdfData = useCallback(() => {
    setLoading(true);
    fetch('https://prizeclasses.in/sql/pdf.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'error') {
          setLoading(false);
          setPdfData(data);
        } else {
          setLoading(false);
          console.log(data.message);
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh',
              }}
            >
              <div className="loading">{data.message}</div>
            </div>
          );
        }
      });
  }, []);

  useEffect(() => {
    fetchPdfData();
  }, [fetchPdfData]);

  const handlePdfClick = async(status, source) => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      // Show loading modal
      showModal({
        circularProgress: true,
        topic: 'Loading PracticeSet...',
        description: 'Please wait...',
      });
      await delay(2000);

    if (status === 'locked' && plan==='Standard') {
      showModal({
        circularProgress: false,
        topic: 'PDF is Locked',
        description:'You need Premium  plan to download these pdf studymaterial',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
        }
      });
    } else {
      const fullUrl = `https://prizeclasses.in/pdf/${source}`; // Construct full URL
      hideModal();
      const downloadLink = document.createElement('a');
      downloadLink.href = fullUrl;
      downloadLink.download = source;
      downloadLink.click();
    }
  };

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <div className="loading">Loading...</div>
      </div>
    );

  return (
    <div style={{ marginTop: '10px' }} className='chapter-list'>
        {pdfData.map((pdf) => (
          <div
            key={pdf.PdfId}
            className="card-container"
            onClick={() => handlePdfClick(pdf.status, pdf.source)}
          >
            <div className="sflex">
              <div className="bar">
                <p style={{ fontWeight: '700', fontSize: '16px', margin: '2px' }}>{pdf.batchName}</p>
                <p style={{ fontWeight: '700', fontSize: '18px', color: 'blue' }}>{pdf.PdfName}</p>
                <span style={{ fontWeight: '700', fontSize: '14px', marginBottom: '5px' }}>
                  {pdf.Year}
                </span>
              </div>

              <div className="barR">
                <p style={{ fontWeight: '700', fontSize: '14px', marginBottom: '15px' }}>
                  PDF QUESTION SET
                </p>
                <button
                  style={{
                    background: 'blue',
                    color: 'white',
                    width: '120px',
                    height: '40px',
                    border: '1px solid green',
                    borderRadius: '5px',
                    fontSize: '14px',
                    fontWeight: '700',
                  }}
                >
                  {pdf.status === 'locked' && plan ==='Standard' ? '🔒 Unlock' : 'Download'}
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default PracticePage;
