
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import styles from '../logins/SignUpPage.module.css';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import { useAppContext } from '../AppContext';





const MyBalance = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);  // Store user profile data
  const { showModal, hideModal } = useAppContext();


  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };
  const historypage = () => {
    navigate('/history');
  };
  const [upi, setUpi] = useState('');
  const [cashout, setCashout] = useState('');
  const [addcash, setAddcash] = useState('');
  const [balance, setBalance] = useState('');


  const cardStyle = {
    boxShadow: '0 4px 12px rgba(252, 183, 194, 0.816)', // Pink shadow
    backgroundColor: '#F2F2F2',
    borderRadius: '10px',
    padding: '20px',
    margin: '30px',
    maxWidth:'800px',
    };

  const balanceStyle = {
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '10px'
  };

  const buttonStyle = {
    backgroundColor: '#28a745',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '200px',
    fontSize: '16px'
  };

  useEffect(() => {
    const userdata = localStorage.getItem('userdata');

    if (userdata) {
      const user = JSON.parse(userdata);
      setProfileData(user);  // Set profile data from localStorage
    fetch(`https://prizeclasses.in/sql/wallet.php?userId=${user?.UserId}`, {
      method: 'GET', // Adjust if needed (e.g., 'POST')
      headers: {
          'Content-Type': 'application/json',
      },
  })
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
          console.log('Response from server:', data);
  
          // If data is an array, destructure it to get the first object.
          const [contestData] = data;
  
          // Access 'balance' from the contestData object.
          const balance = contestData?.wallet_balance;
  
          // Log the balance value
          // console.log('Balance:', balance);
          setBalance(balance)
  
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });  

    } else {
      showModal({
        circularProgress: false,
        topic: 'Account Error ',
        description: 'User not found. Please login /Signup to continue.',
        primaryBtnText: 'Login',
        primaryBtnColor: 'green',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login')
        }
      });   
    }
  }, [profileData?.UserId,hideModal,showModal,navigate]);  // Empty dependency array means this runs once on component mount

   // Function to update input value when a button is clicked
   const handleButtonClick = (amount) => {
    setAddcash(amount); // Set the input value to the clicked amount
  };
    
    const handleAddcashClick = async() => {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      // Show loading modal
      showModal({
        circularProgress: true,
        topic: 'Checking amount...',
        description: 'Please wait...',
      });
      await delay(2000);
      hideModal();
      if (addcash >= 25) {
        window.location.href = `https://prizeclasses.in/Gateway/Addcash.php?userid=${profileData?.UserId}&amount=${addcash}&email=${profileData?.email}`; // Replace with your external link
      }else{
        showModal({
          circularProgress: false,
          topic: 'Cash Limit',
          description:'Add cash amount should be greater or equal to 25 rupees',
          primaryBtnText: 'OK',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
          }
        });
      }
      
  };
    const handleCashoutClick = async() => {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      // Show loading modal
      showModal({
        circularProgress: true,
        topic: 'Checking upi and amount...',
        description: 'Please wait...',
      });
      await delay(2000);
      if (upi.length>5 && cashout >= 150) {
      window.location.href = `https://prizeclasses.in/Gateway/Cashout.php?UserId=${profileData?.UserId}&amount=${cashout}&upi=${upi}&email=${profileData?.email}`; // Replace with your external link
      }else{
        showModal({
          circularProgress: false,
          topic: 'Check Limits',
          description:'UPI ID should be correct & minimum amount of cash out is ₹150',
          primaryBtnText: 'OK',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
          }
        });
      }
  };



  return (
    <div style={{display:'flex', flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar style={{justifyContent:'space-between'}}>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Wallet
          </Typography>
          <button className='Add' onClick={historypage}>History</button>
        </Toolbar>
      </AppBar>

    <div style={{display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center',background:'azure',width:'80%',margin:'10px', borderRadius:'30px' }}>
      <div style={cardStyle} >
         <div style={{ textAlign: 'center', marginBottom: '10px',margin:'1Opx' }}>CURRENT BALANCE</div>
         <div style={balanceStyle}>₹{balance}</div>

        <div style={{display:'grid',justifyContent:'center'}}>
          <div className={styles.signUpinput} style={{justifyContent:'space-between'}}>
         <button className='Add' onClick={() => handleButtonClick(25)}>₹25</button>
         <button className='Add' onClick={() => handleButtonClick(50)}>₹50</button>
         <button className='Add' onClick={() => handleButtonClick(100)}>₹100</button>
          </div>
          <br/>
          <div className={styles.signUpinput}>
          <PinOutlinedIcon  className={styles.signUpicon} />
            <input
              type="number"
              placeholder="Amount"
              value={addcash}
              onChange={(e) => setAddcash(e.target.value)}
            />
          </div>
        </div> 
        
        <div style={{margin:'20px', display:'flex',justifyContent:'center'}}>
       <button style={buttonStyle} onClick={handleAddcashClick}>ADD CASH</button>
       </div>
      </div>

      <div style={cardStyle} >
         <div style={{ textAlign: 'center', marginBottom: '10px',fontWeight:'bolder' }}>CASH OUT</div>

        <div style={{display:'grid',justifyContent:'center'}}>

          <div className={styles.signUpinput}>
            <AccountBalanceWalletOutlinedIcon className={styles.signUpicon} />
            <input
              type="email"
              placeholder="UPI ID"
              value={upi}
              onChange={(e) => setUpi(e.target.value)}
            />
          </div>
          <br/>
          <div className={styles.signUpinput}>
          <PinOutlinedIcon  className={styles.signUpicon} />
            <input
              type="number"
              placeholder="Amount"
              value={cashout}
              onChange={(e) => setCashout(e.target.value)}
            />
          </div>
        </div> 
        
        <div style={{margin:'20px', display:'flex',justifyContent:'center'}}>
       <button style={buttonStyle} onClick={handleCashoutClick}>CASH OUT</button>
       </div>
      </div>
      </div>
    </div>
  );
};

export default MyBalance;
