import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

function NotificationsPage() {
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div>
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Refund policy
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Space to prevent content from being overlapped by AppBar */}
      
      <Box>
      <div class="section">
    <h1>Refund Policy</h1>
    <p>At <strong>PrizeClasses</strong>, we are dedicated to providing a seamless and enjoyable experience for all our participants. To ensure clarity and transparency, we have outlined our refund policy below. Please read it carefully before participating in any contests or quizzes.</p>

    <h2>1. No Cash Refunds</h2>
    <p>We regret to inform you that we do not offer cash refunds for any entries into our contests or quizzes. Once a participant submits their entry, it is considered final and non-refundable. This policy allows us to maintain the integrity and structure of our competitions.</p>

    <h2>2. Conditions for Wallet Refunds</h2>
    <p>While we do not provide cash refunds, we do offer wallet refunds under specific circumstances:</p>
    <ul>
        <li><strong>Suspension of Quizzes:</strong> If a quiz is suspended due to insufficient participants (fewer than the minimum required) the refund will be processed within 24 hour.</li>
        <li><strong>Notification:</strong> Participants will be notified via email or in-app notification if a quiz is suspended and refunds are being processed.</li>
    </ul>

    <h2>3. Participant Responsibility</h2>
    <p>It is important to note that each participant is responsible for their own engagement in contests:</p>
    <ul>
        <li><strong>Joining on Time:</strong> Participants must ensure they join the contest before the scheduled start time. If you fail to join the contest for any reason, including technical difficulties or personal commitments, no refunds will be issued.</li>
        <li><strong>Technical Issues:</strong> While we strive to provide a reliable platform, technical issues can occur. Participants are encouraged to check their internet connectivity and ensure they are logged in before the start of any quiz.</li>
    </ul>

    <h2>4. Finality of Policy</h2>
    <p>By participating in our contests and quizzes, you acknowledge and agree to this refund policy. We encourage you to reach out with any questions or concerns prior to entering a contest.</p>

    <h2>5. Contact Us</h2>
    <p>If you have any inquiries regarding our refund policy or require assistance, please do not hesitate to contact our support team. We are here to help and ensure that your experience on PrizeClasses is as enjoyable as possible.</p>

    <p>Thank you for being a valued member of the PrizeClasses community! We look forward to your participation and wish you the best of luck in our contests and quizzes.</p>
</div>
    {/* </div> */}
      </Box>
    </div>
  );
}

export default NotificationsPage;
