// components/InviteFriendsPage.js
// import React from 'react';
import FAQAccordion from './FAQAccordion';
import {AppBar,Toolbar, Box,Typography, Button ,IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShareIcon from '@mui/icons-material/Share';

import { AppContext } from '../AppContext'; 
import React, { useState,useEffect, useContext  } from 'react';
import { Link } from 'react-router-dom';


const InviteFriendsPage = () => {
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };

  const { dailyChapters, loading, error } = useContext(AppContext);

  if (loading) {
    return <div>Loading chapters...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <React.Fragment>
        <AppBar position="static" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
          <Toolbar><IconButton onClick={handleBackClick}>
          <ArrowBackIcon style={{ color: 'white' }} />
        </IconButton>
        <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>Invite Friends</Typography></Toolbar>
        </AppBar>
      </React.Fragment>
      <div style={{marginLeft:'20px',marginRight:'20px'}}>

      {/* FAQ Accordion Section */}
      <Box my={3}>
        <FAQAccordion />
      </Box>

      {/* <h2>Daily Chapters for {dailyChapters.date}</h2> */}
      {dailyChapters.subjects && dailyChapters.subjects.map((subject, index) => (
        <div key={index}>
          <h3>{subject.subject}</h3>
          <p><strong>Chapter:</strong> {subject.chapter.name}</p>
          {/* Link to the ChapterDetailsPage */}
          <Link to={`/upsc/${index}`}>View Details</Link>
        </div>
      ))}


      {/* Invite Buttons Section */}
      <Box display="flex" justifyContent="space-between" mt={2}>
      <Button
        variant="contained"
        color="success"
        startIcon={<WhatsAppIcon />}
        style={{ width: '48%' }}
      >
        INVITE NOW
      </Button>
      <Button
        variant="contained"
        startIcon={<ShareIcon />}
        style={{ width: '48%'}}
      >
        OTHER OPTIONS
      </Button>
    </Box>
    </div>
     </div>
  );
};

export default InviteFriendsPage;
