import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

function NotificationsPage() {
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <React.Fragment>
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Disclaimer
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Space to prevent content from being overlapped by AppBar */}
      <Box>  
      <div class="section">
        <h2>Disclaimer</h2>
        <p><strong>Last Updated:</strong> 24/09/2024</p>

        <p>The information provided on <strong>PriceClasses</strong> ("PriceClasses," "we," "our," or "us") is intended for general informational and educational purposes only. PriceClasses offers quizzes, learning materials, and educational contests designed to enhance user knowledge and skills in various subject areas. However, the use of this information is at your own risk, and by accessing the platform, you agree to the terms outlined in this disclaimer.</p>
    </div>

    <div class="section">
        <h2>1.  Academic Advice</h2>
        <p>While we make every effort to provide accurate and reliable content, <strong>PriceClasses</strong> is not a substitute for professional academic advice or services. The learning materials, quizzes, and educational resources available on our platform are designed to support your academic journey, but they may not be applicable to your specific situation, level, or goals.</p>

        <ul>
            <li><strong>No Guarantee of Accuracy:</strong> We do not guarantee the accuracy, completeness, or timeliness of any information provided on the platform. The educational content may be subject to changes, updates, or revisions, and we make no warranties that the material is free from errors or omissions.</li>
            <li><strong>Use at Your Own Risk:</strong> Any reliance you place on the information available through <strong>PriceClasses</strong> is strictly at your own risk. PriceClasses shall not be liable for any loss or damage arising from your use of the platform's materials or any reliance on the information provided.</li>
        </ul>
    </div>

    <div class="section">
        <h2>2. Contest Participation and Outcomes</h2>
        <p>PriceClasses provides contests and quizzes intended solely for educational purposes, and participation in these contests does not guarantee any specific academic or financial outcome. The results and rewards of contests are determined based on the performance of users, and no warranties are made regarding the fairness, outcomes, or results of the quizzes or contests.</p>

        <ul>
            <li><strong>No Guarantee of Rewards:</strong> While we may offer rewards for contest participation, these rewards are subject to eligibility criteria, performance, and adherence to the contest rules. PriceClasses makes no guarantee that participants will receive rewards or prizes.</li>
            <li><strong>No Legal or Financial Guarantees:</strong> The educational contests on PriceClasses are not designed to serve as legal, financial, or academic advice, and any rewards provided should not be construed as such.</li>
        </ul>
    </div>

    <div class="section">
        <h2>3. Third-Party Content and Links</h2>
        <p>PriceClasses may contain links to third-party websites, services, or resources, which are provided for your convenience. These external sites are not under our control, and we do not endorse, warrant, or make any representations regarding their content, accuracy, reliability, or services. Your access to any third-party sites is at your own risk, and PriceClasses is not responsible for any damages or losses arising from your use of such third-party content.</p>

        <p><strong>External Links Disclaimer:</strong> PriceClasses may occasionally link to third-party content, but we are not responsible for any content, claims, or damages arising from your use of these sites. The inclusion of any links does not imply an endorsement of the content.</p>
    </div>

    <div class="section">
        <h2>4. No Warranties</h2>
        <p><strong>PriceClasses</strong> is provided "as-is" and "as available," without any warranties or guarantees, express or implied. To the fullest extent permissible by law, we disclaim all warranties, including but not limited to:</p>

        <ul>
            <li>Warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
            <li>Warranties related to the accuracy, reliability, availability, or functionality of the platform.</li>
            <li>Any implied warranties arising out of course of dealing, performance, or usage of trade.</li>
        </ul>

        <p>We do not warrant that the platform will be error-free, secure, or free from interruptions, viruses, or other harmful components.</p>
    </div>

    <div class="section">
        <h2>5. Limitation of Liability</h2>
        <p>Under no circumstances shall PriceClasses, its directors, employees, affiliates, or partners be liable for any direct, indirect, incidental, special, or consequential damages that result from the use of, or the inability to use, <strong>PriceClasses</strong>, even if we have been advised of the possibility of such damages.</p>

        <p>This limitation of liability applies to any loss or damage, including but not limited to:</p>

        <ul>
            <li>Loss of data, revenue, or profits.</li>
            <li>Loss or damage to property.</li>
            <li>Personal injury or wrongful death.</li>
            <li>Any other damages arising from the use of or reliance on the platform, including but not limited to errors, omissions, interruptions, deletion, or defects.</li>
        </ul>

        <p>In jurisdictions that do not allow the exclusion or limitation of liability for incidental or consequential damages, PriceClasses' liability is limited to the maximum extent permitted by law.</p>
    </div>

    <div class="section">
        <h2>6. Changes to the Disclaimer</h2>
        <p>PriceClasses reserves the right to modify or update this Disclaimer at any time without prior notice. Any changes will be posted on this page, and the "Last Updated" date at the top will be revised. We recommend reviewing this Disclaimer periodically to stay informed of any updates.</p>

        <p>By continuing to use <strong>PriceClasses</strong> after such changes, you accept the revised Disclaimer. If you do not agree to this or any future version of the Disclaimer, you must discontinue your use of the platform immediately.</p>
    </div>

    <div class="section">
        <h2>7. Contact Information</h2>
        <p>If you have any questions, concerns, or require further clarification about this Disclaimer, please feel free to contact us:</p>
        <ul>

        <li><strong>Name:</strong> Sandesh Roshan</li>

            <li><strong>Email:</strong> support@prizeclasses</li>
            <li><strong>Address:</strong> 47A/8M, North Loakpur,Nani,Prayagraj,Uttar Pardesh</li>
            <li><strong>Phone:</strong> [6390298282]</li>
        </ul>
    </div>

      </Box>
    </React.Fragment>
  );
}

export default NotificationsPage;
