import React, { useContext, useEffect, useState, useCallback } from 'react';
import { BatchContext } from '../BatchContext';  
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import styles from './ContainerQuiz.module.css'; // Assuming CSS module for styles
import useDisableBackButton from '../useDisableBackButton'; // Adjust the path

const QuizPage = () => {
  useDisableBackButton(); // Disable the browser back/forward buttons
  const { chapters, batch } = useContext(BatchContext);  
  const navigate = useNavigate();
  useDisableBackButton(); // Disable the browser back/forward buttons

  const [chapterData, setChapterData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isAnswered, setIsAnswered] = useState(false); 
  const [timer, setTimer] = useState(0);  

  const [index1, setIndex1] = useState('#ba68c8');  
  const [index2, setIndex2] = useState('#ba68c8');  
  const [index3, setIndex3] = useState('#ba68c8');  
  const [index4, setIndex4] = useState('#ba68c8');  

  const chapterId = localStorage.getItem('CId'); 

  useEffect(() => {
    if (chapters && chapters.length > 0) {
      setChapterData(chapters[chapterId]);  
    }
  }, [chapterId, chapters]);

  
  const resetColors = () => {
    setIndex1('#ba68c8');  
    setIndex2('#ba68c8');  
    setIndex3('#ba68c8');  
    setIndex4('#ba68c8');
  };

  useEffect(() => {
    resetColors();
  }, [currentQuestionIndex]);

  const handleOptionClick = (optionKey, index) => {
    if (!isAnswered) {
      // Retrieve the value from localStorage or initialize it to 0 if it doesn't exist
      let AD = localStorage.getItem('AQ') ? parseInt(localStorage.getItem('AQ'), 10) : 0; 
      AD = AD + 1;
      localStorage.setItem('AQ', AD);
  
      const correctAnswerText = chapterData.chapter.questions[currentQuestionIndex].answer;  
      const selectedAnswerText = chapterData.chapter.questions[currentQuestionIndex].options[optionKey];  
  
      let reviewData = localStorage.getItem('reviewData') 
        ? JSON.parse(localStorage.getItem('reviewData')) 
        : [];
  
      // Prepare the current question's data
      const questionReview = {
        question: chapterData.chapter.questions[currentQuestionIndex].question,
        options: chapterData.chapter.questions[currentQuestionIndex].options,
        correctAnswer: correctAnswerText,
        userAnswer: selectedAnswerText
      };
  
      // Add the current question's data to the review array
      reviewData.push(questionReview);
  
      // Store the updated array back to localStorage
      localStorage.setItem('reviewData', JSON.stringify(reviewData));
  
      // Logic to update the UI based on the answer correctness
      if (selectedAnswerText === correctAnswerText) {
        let CA = localStorage.getItem('CA') ? parseInt(localStorage.getItem('CA'), 10) : 0; 
        CA = CA + 1;
        localStorage.setItem('CA', CA);
  
        if (index === 0) setIndex1('green');
        if (index === 1) setIndex2('green');
        if (index === 2) setIndex3('green');
        if (index === 3) setIndex4('green');
      } else {
        let WA = localStorage.getItem('WA') ? parseInt(localStorage.getItem('WA'), 10) : 0; 
        WA = WA + 1;
        localStorage.setItem('WA', WA);
  
        if (index === 0) setIndex1('red');
        if (index === 1) setIndex2('red');
        if (index === 2) setIndex3('red');
        if (index === 3) setIndex4('red');
  
        const correctIndex = Object.keys(chapterData.chapter.questions[currentQuestionIndex].options).find(
          key => chapterData.chapter.questions[currentQuestionIndex].options[key] === correctAnswerText
        );
        if (correctIndex === "A") setIndex1('green');
        if (correctIndex === "B") setIndex2('green');
        if (correctIndex === "C") setIndex3('green');
        if (correctIndex === "D") setIndex4('green');
      }
  
      setIsAnswered(true);  
  
      // Move to the next question after 2 seconds
      setTimeout(() => {
        if (currentQuestionIndex + 1 < chapterData.chapter.questions.length) {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
          setIsAnswered(false);  
        } else {
          navigateToResult();
        }
      }, 2000);
    }
  };
  

  const navigateToResult = useCallback(() => {
    const remainingTime = 60000 - timer;
    const score = localStorage.getItem('CA') * Math.floor(remainingTime / 1000)-localStorage.getItem('WA'); 
  
    navigate('/result', { 
      state: { 
        totalQuestions: chapterData.chapter.questions.length,
        points: score, 
        timeTaken: timer, 
        topic: chapterData.chapter.topic
      } 
    });
  }, [navigate, timer, chapterData]);
  
  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer < 60000) {
        setTimer((prevTime) => prevTime + 100);
  
      } else {
        clearInterval(countdown);
        navigateToResult();
      }
    }, 100);
    return () => clearInterval(countdown);
  }, [timer, navigateToResult]);

  localStorage.setItem('BA', batch)


  const question = chapterData?.chapter?.questions[currentQuestionIndex];
  const questionOptions = question ? Object.entries(question.options) : [];

  return (
    <>
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#A42FC1' }}>
        <Toolbar>
          <IconButton onClick={() => navigate('/')}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            {batch} - {chapterData?.chapter?.topic}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={styles.quizScreen}>
        <div className={styles.pageCard}>
          <div className={styles.quizContainer}>
            <div className={styles.save}>We are saving ui</div>
          </div>
          <div className={styles.questionCard}>
            <div className={styles.header}>
              <div className={styles.attendedQuestions}>Q. {currentQuestionIndex + 1}</div>
              <div className={styles.circularProgress}>
                <div className={styles.progressCircle}>
                  <span className={styles.currentQuestion}>{timer / 1000} s</span>
                </div>
              </div>
              <div className={styles.totalQuestions}>Q. {chapterData?.chapter?.questions.length}</div>
            </div>

            <h3 className={styles.questionText}>{question?.question}</h3>
          </div>

          <div className={styles.optionsContainer}>
            {questionOptions.map(([optionKey, optionValue], index) => (
              <button
                key={index}
                style={{
                  backgroundColor:
                    index === 0 ? index1 :
                    index === 1 ? index2 :
                    index === 2 ? index3 :
                    index === 3 ? index4 : '#F3E5F5',
                  width: '80%',
                  minHeight: '50px',
                  padding: '10px',
                  border: '2px solid #fff9c4',
                  borderRadius: '10px',
                  color: 'white',
                  fontSize: '16px',
                }}
                onClick={() => handleOptionClick(optionKey, index)}
                disabled={isAnswered}
              >
                {optionValue}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizPage;
