import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useDisableBackButton from '../useDisableBackButton'; // Adjust the path

const Review = () => {
  const navigate = useNavigate();
  useDisableBackButton(); // Disable the browser back/forward buttons

  // Fetch the review data from localStorage
  const reviewData = localStorage.getItem('reviewData') ? JSON.parse(localStorage.getItem('reviewData')) || [] : [];

  return (
    <>
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#A42FC1' }}>
        <Toolbar>
          <IconButton onClick={() => navigate('/')}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Quiz - Answer Review
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        {reviewData.length === 0 ? (
          <div style={{ textAlign: 'center', fontSize: '18px', color: 'red' }}>
            You haven't answered/attended the quiz.
          </div>
        ) : (
          reviewData.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              {/* Question */}
              <h3 style={{ color: '#1A0DAB' }}>
                Question {index + 1}: {item.question}
              </h3>

              {/* Options */}
              <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                {Object.entries(item.options).map(([key, value]) => (
                  <li key={key} style={{ paddingBottom: '5px' }}>
                    <strong>{key}:</strong> {value}
                  </li>
                ))}
              </ul>

              {/* Correct Answer */}
              <div style={{ color: 'green', marginBottom: '5px' }}>
                <strong>Answer:</strong> {item.correctAnswer}
              </div>

              {/* User's Answer */}
              <div
                style={{
                  color: item.correctAnswer === item.userAnswer ? 'green' : 'red',
                }}
              >
                <strong>Your Answer:</strong> {item.userAnswer}
              </div>

              {/* Add a separator */}
              <hr />
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Review;
