// components/FAQAccordion.js
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'How do I utilize my Rewards?',
    answer: 'Bonus DreamCoins redeem discounts, Cash Bonuses, offers, merchandise, etc., on the Rewards shop. As for discounts, you can directly redeem them by joining cash contests.',
  },
  {
    question: 'What does my friend get on registering with my invite code?',
    answer: 'Your friend gets 90% off up to ₹55 on the first contest.',
  },
  {
    question: 'What is the maximum number of invites I can send?',
    answer: 'You can invite as many friends as you like!',
  },
  {
    question: 'What are the Terms & Conditions for using Bonus DreamCoins?',
    answer: 'The DreamCoins can be used only in public cash contests and do not contribute to your level progress.',
  },
];

const FAQAccordion = () => {
  return (
    <div>
      {faqs.map((faq, index) => (
        <Accordion key={index} style={{background:'#d4e6f1'}}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{fontWeight:'bolder'}}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQAccordion;
