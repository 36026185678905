// src/hooks/useDisableBackButton.js

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation

const useDisableBackButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleNavigation = (event) => {
      event.preventDefault(); // Prevent default back/forward button behavior
      navigate('/', { replace: true }); // Always redirect to the main page (/)
    };

    // Push the current state into the history stack
    window.history.pushState(null, '', window.location.href);

    // Listen for both back and forward button click (popstate)
    window.addEventListener('popstate', handleNavigation);

    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener('popstate', handleNavigation);
    };
  }, [navigate]); // Dependency on `navigate`
};

export default useDisableBackButton;
