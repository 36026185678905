import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

function NotificationsPage() {
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <React.Fragment>
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Privacy Policy
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Space to prevent content from being overlapped by AppBar */}
      <Box>

    <div class="section">
        <h1>Privacy Policy</h1>
        <p><strong>Last Updated:</strong> 24/09/2024</p>
        <p>At <strong>PriceClasses</strong> (“PriceClasses,” “we,” “our,” or “us”), we are committed to safeguarding the privacy of our users and ensuring that your personal information is handled responsibly. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you access our website and services. By using <strong>PriceClasses</strong>, you agree to the collection and use of information in accordance with this policy.</p>
        <p>This policy applies to both visitors and registered users of <strong>PriceClasses</strong>.</p>
    </div>

    <div class="section">
        <h2>1. Information We Collect</h2>
        <p>We collect different types of information to provide and improve our services, personalize user experiences, and comply with legal obligations.</p>

        <h3>a. Personal Information</h3>
        <p><strong>Personal Information</strong> refers to information that can be used to identify or contact you. We collect personal information when you voluntarily provide it to us, including:</p>
        <ul>
            <li><strong>Registration Information:</strong> When you create an account, we collect details such as your name, email address, password, and phone number.</li>
            <li><strong>Profile Information:</strong> Users may provide additional information to create profiles, such as academic details, quiz preferences, or other interests.</li>
            <li><strong>Transaction Information:</strong> When you engage in paid services, we collect payment information including credit card details, billing address, and transaction history.</li>
            <li><strong>Communications:</strong> If you contact us via email or support, we may collect the content of your communication and other details you provide.</li>
        </ul>

        <h3>b. Non-Personal Information</h3>
        <p><strong>Non-Personal Information</strong> refers to data that cannot directly identify you, such as:</p>
        <ul>
            <li><strong>Device Information:</strong> Information about the device you use to access <strong>PriceClasses</strong>, including IP address, operating system, browser type, and version.</li>
            <li><strong>Usage Information:</strong> We collect data about how users interact with our platform, including pages visited, features used, and time spent on the site.</li>
            <li><strong>Cookies and Tracking Technologies:</strong> We use cookies, web beacons, and other tracking technologies to collect information about user behavior and preferences.</li>
        </ul>
    </div>

    <div class="section">
        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect to operate, improve, and personalize our services, as well as to communicate with users and comply with legal obligations.</p>

        <h3>a. Providing Services</h3>
        <p>We use your personal information to:</p>
        <ul>
            <li>Create and manage your account.</li>
            <li>Provide access to quizzes, learning materials, and contests.</li>
            <li>Personalize your learning experience by tailoring content and recommendations.</li>
            <li>Process payments for premium services or rewards.</li>
        </ul>

        <h3>b. Improving Services</h3>
        <p>We use non-personal information and analytics to:</p>
        <ul>
            <li>Understand how users engage with our platform and improve its functionality.</li>
            <li>Conduct research and analysis to enhance user experience and introduce new features.</li>
        </ul>

        <h3>c. Communication</h3>
        <p>We use your contact information to:</p>
        <ul>
            <li>Send service-related notifications, such as updates on your account, quizzes, or rewards.</li>
            <li>Send marketing emails, with your consent, to inform you about new features, promotions, or other news.</li>
        </ul>
        <p>You can opt out of marketing communications by following the unsubscribe instructions included in these emails or by contacting us directly.</p>

        <h3>d. Legal Compliance</h3>
        <p>We may use your information to comply with applicable legal requirements, including:</p>
        <ul>
            <li>Responding to lawful requests from government authorities.</li>
            <li>Complying with regulations related to data retention, security, and reporting.</li>
        </ul>
    </div>

    <div class="section">
        <h2>3. How We Share Your Information</h2>
        <p>We do not sell or rent your personal information to third parties. However, we may share your information under the following circumstances:</p>

        <h3>a. Service Providers</h3>
        <p>We may share your information with trusted third-party service providers who assist us in delivering our services. These providers include:</p>
        <ul>
            <li>Payment processors for handling transactions securely.</li>
            <li>Cloud hosting providers to store and manage our data.</li>
            <li>Analytics providers to monitor and analyze usage patterns.</li>
        </ul>

        <p>These third parties are only authorized to use your personal information as necessary to provide services to us and are obligated to maintain confidentiality and security.</p>

        <h3>b. Legal Obligations</h3>
        <p>We may disclose your personal information if required to do so by law or in response to valid legal requests by government authorities or courts.</p>

        <h3>c. Business Transfers</h3>
        <p>In the event of a merger, acquisition, or sale of assets, your information may be transferred to a third party as part of the business transaction. We will notify you of any such transfer and any new privacy policy that may apply to your data.</p>
    </div>

    <div class="section">
        <h2>4. Data Retention</h2>
        <p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, including:</p>
        <ul>
            <li>Maintaining your account and providing you access to our services.</li>
            <li>Complying with our legal obligations, resolving disputes, and enforcing our agreements.</li>
        </ul>

        <p>If you wish to close your account or request that we delete your personal information, please contact us at <strong>support@priceclasses</strong>. We will take steps to delete your data unless we are required by law to retain it.</p>
    </div>

    <div class="section">
        <h2>5. Cookies and Tracking Technologies</h2>
        <p>We use cookies and other tracking technologies to improve the functionality and performance of our platform, personalize content, and analyze usage.</p>

        <h3>a. What Are Cookies?</h3>
        <p>Cookies are small data files stored on your device when you visit a website. They help websites remember your preferences and activities to provide a better user experience.</p>

        <h3>b. Types of Cookies We Use</h3>
        <ul>
            <li><strong>Essential Cookies:</strong> These cookies are necessary for the proper functioning of the platform, such as authenticating users and preventing fraudulent activities.</li>
            <li><strong>Analytics Cookies:</strong> We use these cookies to track how users interact with our site, allowing us to improve our content and features based on user behavior.</li>
            <li><strong>Marketing Cookies:</strong> These cookies allow us to deliver personalized advertisements based on your preferences and browsing activities.</li>
        </ul>

        <h3>c. Managing Cookies</h3>
        <p>You can control and manage cookies through your browser settings. However, disabling certain cookies may affect your ability to use some features of our platform.</p>
    </div>

    <div class="section">
        <h2>6. Security of Your Information</h2>
        <p>We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, or destruction.</p>

        <p>Our security practices include:</p>
        <ul>
            <li>Using encryption technologies to protect sensitive data during transmission.</li>
            <li>Restricting access to personal information to authorized personnel who need it to perform their job functions.</li>
            <li>Regularly reviewing and updating our security practices to ensure compliance with industry standards.</li>
        </ul>

        <p>While we strive to protect your information, please note that no system is completely secure, and we cannot guarantee the absolute security of your data.</p>
    </div>

    <div class="section">
        <h2>7. Your Rights and Choices</h2>
        <p>You have certain rights regarding the personal information we hold about you, including:</p>

        <h3>a. Access and Correction</h3>
        <p>You have the right to access and request a copy of the personal information we hold about you. You may also request that we correct any inaccuracies in your information.</p>

        <h3>b. Data Deletion</h3>
        <p>In certain circumstances, you have the right to request the deletion of your personal information. We will take reasonable steps to comply with your request unless we are required to retain the data by law.</p>

        <h3>c. Opting Out of Marketing Communications</h3>
        <p>You can opt out of receiving marketing communications from us by following the instructions included in our emails or by contacting us directly.</p>

        <h3>d. Data Portability</h3>
        <p>In some cases, you may request a copy of your personal data in a structured, machine-readable format to transfer to another service provider.</p>

        <p>If you wish to exercise any of your rights, please contact us at <strong>support@priceclasses</strong>. We may ask you to verify your identity before processing your request.</p>
    </div>

    <div class="section">
        <h2>8. Children’s Privacy</h2>
        <p>PriceClasses is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If we discover that we have collected personal information from a child under 13, we will take steps to delete that information as quickly as possible.</p>

        <p>If you are a parent or guardian and believe that your child has provided us with personal information, please contact us at <strong>support@priceclasses</strong>.</p>
    </div>

    <div class="section">
        <h2>9. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices, legal requirements, or other factors. When we update the policy, we will revise the "Last Updated" date at the top of this page.</p>

        <p>We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information. Your continued use of <strong>PriceClasses</strong> following any changes indicates your acceptance of the updated policy.</p>
    </div>

    <div class="section">
        <h2>10. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:</p>
        <ul>

        <li><strong>Name:</strong> Sandesh Roshan</li>

            <li><strong>Email:</strong> support@prizeclasses</li>
            <li><strong>Address:</strong> 47A/8M, North Loakpur,Nani,Prayagraj,Uttar Pardesh</li>
            <li><strong>Phone:</strong> [6390298282]</li>
        </ul>
    </div>

      </Box>
    </React.Fragment>
  );
}

export default NotificationsPage;
