import React, { useState,useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './SignUpPage.module.css';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';


const SignUpPage = () => {
  const [action, setAction] = useState("Login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

    // Ensure batch is set correctly when component mounts
    useEffect(() => {
      const userdata = localStorage.getItem('userdata');
      if (userdata) {
        navigate('/')
      }
    }, [navigate]);



  const { showModal, hideModal } = useAppContext();
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const validateForm = async() => {
    await delay(2000); // Delay for 2 seconds
  
    // Check if email or password is empty
    if (!email || !password) {
      showModal({
        circularProgress: false,
        topic: 'Fill Details',
        description: 'Email and password are required.',
        primaryBtnColor: 'red',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
        }
      });
      return false;
    }
  
    // Email pattern to allow only specific domains
    const emailPattern = /^[^\s@]+@(gmail\.com|outlook\.com|apple\.com|yahoo\.com|hotmail\.com|icloud\.com|me\.com|mac\.com)$/i;
  
    // Check if the email matches the pattern
    if (!emailPattern.test(email)) {
      showModal({
        circularProgress: false,
        topic: 'Check Email',
        description: 'Please enter a valid email address from Gmail, Outlook, Apple (iCloud, Me, Mac), Yahoo, or Hotmail.',
        primaryBtnColor: 'red',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
        }
      });
      return false;
    }
  
    // Validate name only if the action is "Sign Up"
    if (action === "Sign Up" && name.length < 3) {
      showModal({
        circularProgress: false,
        topic: 'Check Name',
        description: 'Name must be at least 3 characters long.',
        primaryBtnColor: 'red',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
        }
      });
      return false;
    }
  
    // If all checks passed, return true
    return true;
  };
  
  // Handle sign-up process
const handleSignUp = async () => {
  // Await validateForm to ensure form validation completes
  if (!await validateForm()) return;
  setLoading(true);


  try {
    const response = await axios.post('https://prizeclasses.in/sql/signup.php', {
      action: 'signup',
      name,
      email,
      password,
    });

    const { status, message, userdata } = response.data;
    if (status === 'success') {
      localStorage.clear();
      localStorage.setItem('userdata', JSON.stringify(userdata));
      
      showModal({
        circularProgress: false,
        topic: 'Signup Successfully',
        description: '₹50 is added to your account as signup bonus',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/')
        }
      });
    } else {
      showModal({
        circularProgress: false,
        topic: 'Opps! Signup Error',
        description: message,
        primaryBtnText: 'OK',
        hidePrimary: false,
        primaryBtnColor: 'red',
        onPrimaryClick: () => {
          hideModal();
        }
      });
    }
  } catch (error) {
    // Show the actual error message instead of the object
    showModal({
      circularProgress: false,
      topic: 'Opps! Signup Error',
      description: error.response?.data?.message || error.message,
      primaryBtnText: 'OK',
      primaryBtnColor: 'red',
      hidePrimary: false,
      onPrimaryClick: () => {
        hideModal();
      }
    });
  } finally {
    setLoading(false);
  }
};

// Handle login process
const handleLogin = async () => {
  // Await validateForm to ensure form validation completes
  if (!await validateForm()) return;

  setLoading(true);

  try {
    const response = await axios.post('https://prizeclasses.in/sql/login.php', {
      action: 'login',
      email,
      password,
    });

    const { status, message, userdata } = response.data;

    if (status === 'success') {
      localStorage.clear();
      localStorage.setItem('userdata', JSON.stringify(userdata));
      showModal({
        circularProgress: false,
        topic: 'Login Successfully',
        description: `Welcome back, ${message}`,
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/')
        }
      });
    } else {
      showModal({
        circularProgress: false,
        topic: 'Opps! Login Error',
        description: message,
        primaryBtnColor: 'red',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
        }
      });
    }
  } catch (error) {
    // Show the actual error message instead of the object
    showModal({
      circularProgress: false,
      topic: 'Opps! Login Error',
      description: error.response?.data?.message || error.message,
      primaryBtnColor: 'red',
      primaryBtnText: 'OK',
      hidePrimary: false,
      onPrimaryClick: () => {
        hideModal();
      }
    });
  } finally {
    setLoading(false);
  }
};

// Handle reset password click
const handleResetClick = () => {
  navigate('/reset');
};

// Handle form submission for sign-up or login
const handleSubmit = async () => {
  if (action === "Sign Up") {
    showModal({
      circularProgress: true,
      topic: 'Checking signup Details...',
      description: 'Please wait...',
    });
    await handleSignUp();
  } else {
    setAction("Sign Up");
  }
};

// Handle login button click
const handleLoginClick = async () => {
  if (action === "Login") {
    showModal({
      circularProgress: true,
      topic: 'Checking login Details...',
      description: 'Please wait...',
    });
    await handleLogin();
  } else {
    setAction("Login");
  }
};

  
  return (
    <div className={styles.signUpmain}>
      <div className={styles.signUpcontainer}>
        <div className={styles.signUpheader}>
          {/* <WestOutlinedIcon className={styles.signUpicon} onClick={() => navigate('/')} /> */}
          <div className={styles.signUptext}>{action}</div>
        </div>
        <div className={styles.signUpinputs}>
          {action === "Login" ? null : (
            <div className={styles.signUpinput}>
              <AccountCircleOutlinedIcon className={styles.signUpicon} />
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={loading}
              />
            </div>
          )}
          <div className={styles.signUpinput}>
            <EmailOutlinedIcon className={styles.signUpicon} />
            <input
              type="email"
              placeholder="Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
            />
          </div>
          <div className={styles.signUpinput}>
            <LockOutlinedIcon className={styles.signUpicon} />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
          </div>
          
        </div>
        {action === "Sign Up" ? null : (
          <div className={styles.signUpforgot}>
            Lost Password? <span onClick={handleResetClick}>Click here</span>
          </div>
        )}
        <div className={styles.signUpsubmitcontainer}>
          <div
            className={action === "Sign Up" ? styles.signUpsubmit : styles.signUpsubmitgray}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading && action === "Sign Up" ? <CircularProgress size={24} /> : "Sign Up"}
          </div>
          <div
            className={action === "Login" ? styles.signUpsubmit : styles.signUpsubmitgray}
            onClick={handleLoginClick}
            disabled={loading}
          >
            {loading && action === "Login" ? <CircularProgress size={24} /> : "Login"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
