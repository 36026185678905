import React from 'react';
import './App.css';
// import { useMediaQuery } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MobileHomePage from './components/MobileHomePage'; 
// import NotificationsPage from './components/NotificationsPage'; 
import MockPage from './components/MockPage.js'; 
import StudyPage from './components/StudyPage.js'; 
import SignUpPage from './logins/SignUpPage.js'; 
import ResetPage from './logins/ResetPage.js';
import InvitePage from './SidePages/InvitePage.js';
import { AppProvider } from './AppContext';  // Import the AppProvider
import { BatchProvider } from './BatchContext';  // Import the BatchProvider
import LayoutWithAds from './components/LayoutWithAds'; 
import TermsPage from './SidePages/TermsPage.js';
import Refund from './SidePages/refund.js';
import About from './SidePages/about.js';
import LegalityPage from './SidePages/LegaltyPage.js';
import PrivacyPage from './SidePages/PrivacyPage.js';
import HelpPage from './SidePages/HelpPage.js';
import HowPage from './SidePages/HowPage.js';
import SubscriptionPage from './SidePages/SubscriptionPage.js';
import WalletPage from './components/WalletPage.js';
import TransactionPage from './components/Transaction.js';
import ChaptersPage from './components/ChaptersPage.js';
import ContainerPage from './components/ContainerPage';    // New ContainerPage component to load content
import ContainerQuiz from './quiz/ContainerQuiz.js';    // New ContainerPage component to load content
import ContainerQuiz2 from './quiz/ContainerQuiz2.js';    // New ContainerPage component to load content
import ResultPage from './quiz/ResultPage.js';    // New ContainerPage component to load content
import RankingPage from './quiz/RankingPage.js';    // New ContainerPage component to load content
import ContestPage from './components/ContestPage.js';    // New ContainerPage component to load content
import MyContestPage from './components/MyContestPage.js';    // New ContainerPage component to load content
import EntryPage from './quiz/EntryPage.js';    // New ContainerPage component to load content
import JoinPage from './quiz/JoinPage.js';    // New ContainerPage component to load content
import Review from './quiz/Review.js';  
import Alert from './AlertMobile.js';  

const App = () => {
  // const isMobile = useMediaQuery('(max-width:600px)'); // Media query for mobile detection

  return (
    <BatchProvider>
    <AppProvider> 
      <Router>
        <LayoutWithAds>
          <Routes>
            <Route path="*" element={<MobileHomePage /> } />
            {/* <Route path="/notifications" element={<NotificationsPage />} /> */}
            <Route path="/login" element={<SignUpPage />} />
            <Route path="/reset" element={<ResetPage />} />
            <Route path="/refer-and-earn" element={<InvitePage/>} />
            <Route path="/terms" element={<TermsPage/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/refund" element={<Refund/>} />
            <Route path="/legality" element={<LegalityPage/>} />
            <Route path="/privacy" element={<PrivacyPage/>} />
            <Route path="/help" element={<HelpPage/>} />
            <Route path="/how" element={<HowPage/>} />
            <Route path="/subscription" element={<SubscriptionPage/>} />
            <Route path="/wallet" element={<WalletPage/>} />
            <Route path="/history" element={<TransactionPage/>} />
            <Route path="/content/:chapterId" element={<ContainerPage />} />  {/* Dynamic content route */}
            <Route path="/containerQuiz" element={<ContainerQuiz />} />  {/* Dynamic content route */}
            <Route path="/containerQuiz2" element={<ContainerQuiz2 />} />  {/* Dynamic content route */}
            <Route path="/chapter" element={<ChaptersPage/>} />
            <Route path="/entry" element={<EntryPage/>} />
            <Route path="/join" element={<JoinPage/>} />
            <Route path="/result" element={<ResultPage/>} />
            <Route path="/ranking" element={<RankingPage/>} />
            <Route path="/contest" element={<ContestPage/>} />
            <Route path="/mycontest" element={<MyContestPage/>} />
            <Route path="/StudyPage" element={<StudyPage/>} />
            <Route path="/MockPage" element={<MockPage/>} />
            <Route path="/review" element={<Review/>} />
            <Route path="/alert" element={<Alert/>} />

          </Routes>
        </LayoutWithAds>
      </Router>
    </AppProvider>
    </BatchProvider>     

  );
};

export default App;
