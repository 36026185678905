import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Box, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseIcon from '@mui/icons-material/Close';  // Import Close icon

const Sidebar = () => {
  const [profileData, setProfileData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userdata = localStorage.getItem('userdata');

    if (userdata) {
      const user = JSON.parse(userdata);
      setProfileData(user);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef]);

  return (
    <>

      <MenuOutlinedIcon 
        onClick={toggleSidebar}
        style={{ 
          marginRight: '10px', 
          padding: 2, 
          color: '#813588', 
          backgroundColor: '#F3E5F5', 
          borderRadius: '10px', 
          border: '5px solid #f3e5f5' 
        }} 
      />

      {isOpen && (
        <Box 
          ref={sidebarRef}
          sx={{ 
            width: 300, 
            height: '100vh', 
            bgcolor: 'white', 
            position: 'fixed', 
            color:'purple',
            left: 0, 
            top: 0, 
            overflowY: 'auto', 
            boxShadow: '2px 0 5px rgba(1,1,1,0.5)',
            zIndex: 1000, 
          }}
        >
          {/* Close Button */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px',background:'#F3E5F5' }} onClick={toggleSidebar}
          >
            <Typography variant="h6" sx={{ color: 'purple' }}>Close Menu</Typography>
            <IconButton onClick={() => setIsOpen(false)} size="small" sx={{ color: 'purple' }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Profile Section - Show only if logged in */}
          {isLoggedIn ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#FFA500',
                color: 'white',
                padding: '16px',
              }}
            >
              <Avatar 
                alt={profileData?.name} 
                src="/path/to/profile-pic.jpg" 
                sx={{ width: 56, height: 56, marginRight: '16px', border: '2px solid #F3E5F5', backgroundColor: '#813588' }} 
              />
              <Box>
                <Typography variant="h6">{profileData?.name}</Typography>
                <Typography variant="body2">{profileData?.email}</Typography>
                <br />
                <Typography variant="body2" color='black'>USERID: {profileData?.UserId}</Typography>
              </Box>
            </Box>
          ) : (
            <List>
              <ListItem disablePadding onClick={() => navigate('/login')}>
                <ListItemButton>
                  <ListItemIcon style={{ color: 'purple' }}>
                    <LoginIcon />
                  </ListItemIcon>
                  <ListItemText primary="Login" />
                </ListItemButton>
              </ListItem>
            </List>
          )}

          <Divider />

          <List>
            {['How to win Contest', 'About Us', 'Refund Policy', 'Disclaimer', 'Privacy Policy', 'Terms And Conditions'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => navigate(index === 0 ? '/how' : index === 1 ? '/about' : index === 2 ? '/refund' : index === 3 ? '/legality' : index === 4 ? '/privacy' : '/terms')}>
                  <ListItemIcon style={{ color: 'purple' }}>
                    {index === 0 ? <EmojiEventsOutlinedIcon /> : index === 1 ? <InfoIcon /> : index === 2 ? <RemoveShoppingCartIcon /> : index === 3 ? <GavelOutlinedIcon /> : index === 4 ? <ShieldOutlinedIcon /> : <DescriptionOutlinedIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Divider />

          <List>
            <ListItem disablePadding onClick={() => navigate('/help')}>
              <ListItemButton>
                <ListItemIcon style={{ color: 'purple' }}>
                  <SupportAgentOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Contact Us" />
              </ListItemButton>
            </ListItem>
          </List>
          {isLoggedIn ? (
            <>
              <Divider />
              <List>
                <ListItem disablePadding onClick={() => {
                  localStorage.clear();
                  navigate('/login');
                }}>
                  <ListItemButton>
                    <ListItemIcon style={{ color: 'purple' }}>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          ) : (
            ''
          )}

          <Box sx={{ padding: '16px', textAlign: 'center' }}>
            <Typography variant="caption" color="textSecondary">
              VERSION 2.20.1
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
