import React, { useState,useEffect } from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import styles from './Help.module.css'; // Importing the CSS module
import {useAppContext} from '../AppContext';


function HelpPage() {
  const navigate = useNavigate();
  const [upi, setUpi] = useState('');
  const [profileData, setProfileData] = useState(null);
  const { showModal, hideModal } = useAppContext();


  useEffect(() => {
      }, [hideModal,showModal,navigate]);

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };
  // Function to handle back navigation
  const handleSubmit = async() => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    // Show loading modal
    showModal({
      circularProgress: true,
      topic: 'Sending message...',
      description: 'Please wait...',
    });
    await delay(2000);
    const userdata = localStorage.getItem('userdata');
    if (userdata) {
      const user = JSON.parse(userdata);
      setProfileData(user);
      fetch(`https://prizeclasses.in/sql/query.php?userId=${profileData?.user}&message=${upi}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.status !== 'error') {
            showModal({
              circularProgress: false,
              topic: 'Message Send',
              description: 'Hi! we got your message here, we may took 72hr to respond, please have patience ',
              primaryBtnText: 'OK',
              hidePrimary: false,
              onPrimaryClick: () => {
                hideModal();
              }
            });  
          }else{
            showModal({
              circularProgress: false,
              topic: 'Failed To Message Send',
              description:data.message,
              primaryBtnText: 'OK',
              hidePrimary: false,
              onPrimaryClick: () => {
                hideModal();
              }
            });
          }
        });
    } else {
      showModal({
        circularProgress: false,
        topic: 'UserID Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');
        }
      });        
    }

  };

  return (
    <React.Fragment>
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Help | Support
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Space to prevent content from being overlapped by AppBar */}
      <Box p={3}>
        <div className={styles.helpContainer}>
            {/* Topics Section */}
            {/* <section className={styles.helpcategories}> */}
              {/* <h3 className="title">Topics</h3>
              <div className={styles.helpcategoryList}>
                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F680;</span>
                    Getting Started</h4>
                    <p className={styles.helpcategoryDescription}>
                      What is PriceClasses, getting started & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F4B3;</span>
                    My Balance</h4>
                    <p className={styles.helpcategoryDescription}>
                      Winnings, Withdrawals, Deposits & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F464;</span>
                    Profile & Verification</h4>
                    <p className={styles.helpcategoryDescription}>
                      View/Modify details, Verification & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F3AE;</span>
                    How to win contests</h4>
                    <p className={styles.helpcategoryDescription}>
                      Create Teams, Join Contests & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F381;</span>
                    Offers & Rewards</h4>
                    <p className={styles.helpcategoryDescription}>
                      Invite Friends, D11 Champions & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                  <span className={styles.helpcategoryIcon}>&#x1F4F6;</span>
                     Others</h4>
                    <p className={styles.helpcategoryDescription}>
                      Network, Security & Legality
                    </p>
                  </a>
                </li>
              </div>
            </section> */}

            {/* Contact Us Section */}
            <section className={styles.helpcontactUsSection}>
              <h3>Do you need any help we are here?</h3>
              <p>Feel free to contact us with any questions or concerns. You can write query on our website or email us directly. We appreciate your interest and look forward to hearing from you.</p>
              <div className={styles.signUpinput}>
           
            <textarea
              type="text"
              placeholder="Write your query, we will respond you on your mail....."
              rows="6" // Number of visible text lines
              cols="50" // Width of the textarea
              value={upi}
              onChange={(e) => setUpi(e.target.value)}
            />
          </div>
          <br/>
              <span className={styles.helpcontactUsLink} onClick={handleSubmit}>
                Send Us
              </span>
            </section>
            <p><strong>Note:- </strong>We may took up to 72 hr to respond, Kindly have patience. Thanks!</p>
          </div>
      </Box>
    </React.Fragment>
  );
}

export default HelpPage;
